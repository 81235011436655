import React from "react";
import cn from "classnames";
import styles from "./Offer.module.sass";
import pstyles from "../Page/Page.module.sass";
import Dbutton from "../Dbutton";

const Offer = ({ className }) => {
  return (
    <div className={cn(className, styles.section)}>
      <div className={cn(styles.sbg, "container", styles.container)}>
        <div className={cn("stage", styles.stage)}>
          Its free
        </div>
        <h2 className={cn("h1", styles.title)}>
          Recharge and Pay Bills Safely From <span className={pstyles.txttick11}>Home</span>
        </h2>
        <div className={styles.text}>         
          <div className={styles.logo}>
              <img src="/images/playstore.png" alt={"InfiPe"} height={180}/>
            </div>
        <div > 
        </div>
        
        <Dbutton stylebtn={styles.btnr}/>
        </div>
      </div>
    </div>
  );
};

export default Offer;
