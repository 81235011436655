import React from "react";
import cn from "classnames";
import styles from "./Desc.module.sass";

const Team = () => {

  return (
    <div className={cn("sectionprivacy", styles.sectionprivacy)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("containerhead", styles.containerhead)}>
          <div className={cn("stage-small", styles.stage)}>
            Getting started with
          </div>
          <h2 className={cn("h2", styles.titlehead)}>Refunds Policy</h2>
        </div>
        <div className={styles.top}>
          <div className={styles.info}>
            All transactions done on BillDrive platform are final with no refund permitted. You are responsible for entering the consumer number for which you are making the payment. BillDrive is not responsible for any incorrect mobile number or DTH account number or incorrect data card information.
            You can view the Billdrive <a target="_" href="https://billdrive.in/privacy-policy">Privacy Policy</a>.
          </div>
          <div className={styles.info}>However, if in a transaction performed by you on the BillDrive Platform, money has been deducted from your card or bank account and the service is not delivered within 24 hours of the completion of the transaction, then you shall inform us by sending an e mail to our customer services i.e., support@billdrive.in. In the e-mail, please add the following details – transaction Id, transaction date and amount.</div>
          <div className={styles.info}>BillDrive will investigate the incident and, if it is found that money was indeed charged to your card or bank account without delivery of the recharge, then you will be refunded the money within 7 working days from the date of receipt of your e mail. It will take 3-7 working days for the money to show in your bank account depending on your bank’s policy.</div>
          
          <div className={styles.info}>Note: BillDrive will not be responsible for wrong recharges due to any incorrect information provided by the user. In all such cases no refund will be provided to the user.</div>
          
          <h4 className={cn("h4", styles.title)}>Recharge and Bill Payments</h4>
          <div className={styles.info}>
          a. Successful Transactions: Once a recharge or bill payment transaction is successfully processed, refunds are generally not provided. It is essential to review and verify the details before confirming the transaction.
          </div>
          <div className={styles.info}>
          b. Failed Transactions: In the event of a failed transaction where the amount has been debited from your account but the recharge or bill payment is not completed, we will initiate a refund process. Before initiating a refund, the user must verify their KYC. The refunded amount will be credited back to your bank account within a reasonable time.
          </div>

          <h4 className={cn("h4", styles.title)}>Refund Process</h4>
          <div className={styles.info}>
          a. To request a refund, you must contact our customer support team within the specified timeframe and provide the necessary details related to the transaction or issue.
          </div>
          <div className={styles.info}>
          b. We will assess the refund request based on the applicable terms and conditions and our discretion.
          </div>
          <div className={styles.info}>
          c. Refunds, if approved, will be processed using the original payment method or an alternative method determined by us.
          </div>

          <h4 className={cn("h4", styles.title)}>Exceptions</h4>
          <div className={styles.info}>
          Certain circumstances may warrant exceptions to this refund policy, such as legal requirements or extenuating circumstances. In such cases, we will assess the situation on an individual basis and determine the appropriate course of action.
          </div>

          <h4 className={cn("h4", styles.title)}>Contact Us</h4>
          <div className={styles.info}>
          If you have any questions, concerns, or refund requests, please reach out to our customer support team using the contact information provided on our platform.
          </div>
          <div className={styles.info}>
          Please note that this refund policy is subject to change without prior notice. It is your responsibility to review the policy periodically for any updates.
          </div>
          <div className={styles.info}>
          By using our platform, you acknowledge that you have read, understood, and agreed to the terms of this refund policy. If you do not agree with any part of this policy, please refrain from using our services.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
