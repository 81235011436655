import React from "react";
import cn from "classnames";
import styles from "./Breadcrumbs.module.sass";

const Breadcrumbs = () => {
  return (
    <div className={styles.breadcrumbs}>
      <div className={cn("container", styles.container, "containerc", styles.containerc)}>
        <div className={styles.wrap}><div className={styles.warpinner}></div></div>
        <div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
