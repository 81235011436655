import React from "react";
import cn from "classnames";
import styles from "./Desc.module.sass";

const Team = () => {

  return (
    <div className={cn("sectionprivacy", styles.sectionprivacy)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("containerhead", styles.containerhead)}>
          <div className={cn("stage-small", styles.stage)}>
            Date Last Modified: July 20, 2024
          </div>
          <h2 className={cn("h2", styles.titlehead)}>Terms & Conditions</h2>
        </div>
        <div className={styles.top}>
          <div className={styles.info}>
          Welcome to BillDrive Technology Private Limited ("Company", "we", "our", "us"). This page states the Terms and Conditions under which you (Visitor) may visit this website www.billdrive.in Please read this page carefully. If you do not accept the Terms and Conditions stated here, we would request you to exit this site. The business, any of its business divisions and / or its subsidiaries, associate companies or subsidiaries to subsidiaries or such other investment companies (in India or abroad) reserve their respective rights to revise these Terms and Conditions at any time by updating this posting. You should visit this page periodically to re-appraise yourself of the Terms and Conditions, because they are binding on all users of this Website.
            You can view the BillDrive <a target="_" href="https://billdrive.in/privacy-policy">Privacy Policy</a>.
          </div>
         
          <h4 className={cn("h4", styles.titletop)}>Services</h4>
          <div className={styles.info}>
          BillDrive Technology Private Limited offers the following services:
          </div>
          <ul className={styles.roman}>
            <li><b>Comprehensive Coverage:</b> Manage all your bills and recharges in one place. From mobile recharges to utility bill payments, our app streamlines your digital payment experience.</li>
            <li><b>Daily News Updates:</b> Stay informed with the latest news updates directly within the app.</li>
            <li><b>Exciting Mini Games:</b> Access 25+ mini games for entertainment and earn rewards.</li>
            <li><b>Cashback on Every Purchase:</b> Enjoy cashback rewards with every transaction you make on our platform, enhancing your savings.</li>
            <li><b>Contests and Rewards:</b> Participate in engaging contests based on the tickets you earn through bill payments, recharges, shopping, and playing mini games.</li>
          </ul>

          <h4 className={cn("h4", styles.titletop)}>Use of Content</h4>
          
          <div className={styles.info}>
          All content and material on the Website, including but not limited to information, images, marks, logos, designs, pictures, graphics, text, content, hyperlinks, multimedia clips, animation, games, and software (collectively referred to as "Content"), whether or not belonging to BillDrive, are protected by applicable intellectual property laws. Additionally, all chat content, messages, images, recommendations, and emails sent by any Users can be logged/recorded by us and shall form part of the Content. BillDrive platform is free to use this material in any manner whatsoever.
          </div>
          <div className={styles.info}>
          Our Website may contain information about or hyperlinks to third parties. We are not responsible for the content on such websites and do not provide any express or implied warranty of the accuracy, completeness, suitability, or quality of the content belonging to such third-party websites. You may choose to rely on any third-party content posted on our Website solely at your own risk and liability.
          </div>
          <div className={styles.info}>
            If you visit a third-party website through any third-party content posted on BillDrive.in,
            you will be subject to the terms and conditions applicable to that content. We neither
            control nor are we responsible for the content published on such third-party
            websites. The existence of a link to a third-party website on our Website is not in any
            way an endorsement of that website by us.
          </div>

          <h4 className={cn("h4", styles.title)}>Ownership and Use of Software</h4>
          <div className={styles.info}>
          Commercial use of our Software is strictly forbidden. Users are only allowed to use the Software for their personal use. Users may not attempt to modify, decompile, reverse-engineer, or disassemble the Software in any way. The use of artificial intelligence, including without limitation robots, is strictly forbidden in connection with the Software.
          </div>
          <div className={styles.info}>
          Use of BillDrive's name and any other names or marks associated with the Site, the Services, or the Software is strictly forbidden unless used with the express written permission of the Company. The Site, the Services, and the Software, including its code, documentation, appearance, structure, and organization, is an exclusive product of the Company.
          </div>

          <h4 className={cn("h4", styles.title)}>Modification to Terms</h4>
          <div className={styles.info}>
          We reserve the right to modify these Terms at any time. The date of the last modification precedes these Terms. When we make modifications that we consider material, we may attempt to notify you through the Service; however, you should periodically review the most up-to-date version of the Terms. Modifications are effective when posted, and your use of the Service after the effective date constitutes your acceptance of the Terms as modified.
          </div>

          <h4 className={cn("h4", styles.title)}>User Registration and Account Security</h4>
          <div className={styles.info}>
          To access our services, you must register and create an account. During registration, we collect your username, password, email, phone number, state, and date of birth to verify your age. In certain scenarios, we also collect your bank account number, IFSC code, and PAN number for processing refunds and enabling cashback and reward withdrawals.
          </div>

          <div className={styles.info}>
          You are responsible for maintaining the confidentiality of your account details and for all activities that occur under your account. You must not share your password with anyone. We are not responsible for any unauthorized access to your account.
          </div>
           
          <h4 className={cn("h4", styles.titletop)}>Indemnity</h4>
          <div className={styles.info}>
          To the extent permitted by law, you agree to indemnify, save, hold harmless, and defend us from any claims, actions, suits, taxes, damages, injuries, causes of action, penalties, interest, demands, expenses, and/or awards asserted or brought against us by any person in connection with:
          </div>
          <ul className={styles.roman}>
            <li>Infringement of their intellectual property rights by your publication of any Content on our Website.</li>
            <li>Defamatory, offensive, or illegal conduct of any other user.</li>
            <li>Use, abuse, or misuse of your user account in any manner.</li>
            <li>Any technical failures, system breakdowns, defects, delays, interruptions, or data corruption arising in connection with your use of our Website.</li>
            <li>Access to your account by any other person using your username or password.</li>
          </ul>

          <h4 className={cn("h4", styles.titletop)}>Limitation of Liability</h4>
          <div className={styles.info}>
          User agrees that neither Company nor its group companies, directors, officers, or employees shall be liable for any direct, indirect, incidental, special, consequential, or exemplary damages resulting from the use or inability to use the service, or from unauthorized access to or alteration of user's transmissions or data, even if the Company has been advised of the possibility of such damages.
          </div>
                        
          <h4 className={cn("h4", styles.title)}>Privacy and Data Collection</h4>
          <div className={styles.info}>
          BillDrive's Privacy Policy is an integral part of these Terms and is incorporated into this document by reference. If you do not agree to our Privacy Policy, you should not use the Application or submit any personally identifiable information through the Application. Questions regarding privacy issues should be directed to <a target="_" href="mailto:support@billdrive.in">support@billdrive.in</a>.
          </div>

          <h4 className={cn("h4", styles.title)}>Processing Fees</h4>
          <div className={styles.info}>
          BillDrive.in reserves the right to charge convenience fees for certain recharges and transaction fees based on completed transactions using the services. BillDrive.in further reserves the right to alter any fees from time to time without notice. The User is responsible for all charges, fees, duties, taxes, and assessments arising out of the use of the services.
          </div>
          
          <h4 className={cn("h4", styles.titletop)}>Cashback Rewards</h4>
          <div className={styles.info}>
          We offer cashback rewards with every transaction made on our platform, which is designed to enhance your savings. Please note that the cashback rewards are subject to the following terms:
          </div>
          <ul className={styles.roman}>
            <li>Eligibility for Cashback: Cashback is provided based on transactions conducted through our platform and is contingent upon our receipt of commission from our tied-up providers. Cashback rewards are only applicable if we receive commission from the service providers associated with your transaction.</li>
            <li>Commission Dependency: We may display cashback rewards in the user interface (UI) of our platform; however, cashback will only be provided if we actually receive commission from the associated providers. If we do not receive a commission, no cashback will be granted, even if it appears to be available on the UI.</li>
            <li>Changes to Cashback Policy: We reserve the right to modify or withdraw the cashback rewards policy at our discretion. Any changes will be communicated through the platform or updated in the Terms & Conditions.</li>
            <li>Disputes: Any disputes regarding the cashback rewards will be handled in accordance with the dispute resolution process outlined in our Terms & Conditions.</li>
            <li>Access to your account by any other person using your username or password.</li>
          </ul>

          <div className={styles.info}>
          By using our platform, you acknowledge and agree to these terms regarding cashback rewards.
          </div>

          <h4 className={cn("h4", styles.title)}>Refunds</h4>
          <div className={styles.info}>
          If the user enters any wrong information and the recharge is successful, in all such cases no refund will be provided.
          </div>

          <h4 className={cn("h4", styles.title)}>Maintenance and Updates to the Application</h4>
          <div className={styles.info}>
          BillDrive conducts maintenance work on its system periodically to ensure security and integrity. A portion or all of the features of the Application may not be available during maintenance periods. BillDrive may update the Application with or without notifying you.
          </div>

        </div>
      </div>
    </div>
  );
};

export default Team;
