import React from "react";

import RDesc from "./RDesc";
import Info from "./Info";
import Subheader from "./Subheader";
import { Helmet, HelmetProvider } from "react-helmet-async";
import OfferShopping from "../../components/OfferShopping";

const RechargeHome = () => {

  return (
    <HelmetProvider>
      <Helmet>
        <title>BillDrive - Mobile Recharge & Utility Bill Payment</title>
        <meta property="title" content="BillDrive - Shopping Cashback & Get More Rewards" /> 
        <meta name="description" content="Shop smart and save with shopping cashback rewards. Get money back on every purchase, maximizing your savings while enjoying a rewarding shopping experience." />
        <meta property="og:title" content="BillDrive - Shopping Cashback & Get More Rewards" />
        <meta name="og:description" content="Shop smart and save with shopping cashback rewards. Get money back on every purchase, maximizing your savings while enjoying a rewarding shopping experience." />
        <meta property="og:url" content="https://billdrive.in/" />
        <meta property="twitter:title" content="BillDrive - Shopping Cashback & Get More Rewards" />
        <meta name="twitter:description" content="Shop smart and save with shopping cashback rewards. Get money back on every purchase, maximizing your savings while enjoying a rewarding shopping experience." />
      </Helmet>  
      <RDesc />  
      <Subheader/>
      <Info />  
      <OfferShopping />
    </HelmetProvider>
  );
};

export default RechargeHome;
