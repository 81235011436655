import React from "react";
import cn from "classnames";
import styles from "./Desc.module.sass";

const Team = () => {

  return (
    <div className={cn("sectionprivacy", styles.sectionprivacy)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("containerhead", styles.containerhead)}>
          <div className={cn("stage-small", styles.stage)}>
            Date Last Modified: July 20, 2024
          </div>
          <h2 className={cn("h2", styles.titlehead)}>Privacy Policy</h2>
        </div>
        <div className={styles.top}>
          <h4 className={cn("h4", styles.title)}>Our Privacy Commitment</h4>
          <div className={styles.info}>
          BillDrive Technology Private Limited ("BillDrive", "we", "our", "us") is committed to protecting your privacy. This Privacy Policy describes our practices for collecting, using, disclosing, maintaining, and protecting your personal information across our media platforms, including our website and mobile applications (collectively, the “Services”). By using our Services, you agree to the terms of this Privacy Policy.
          </div>
          <h4 className={cn("h4", styles.title)}>1. The Information We Collect</h4>
          <div className={styles.info}>
            To provide you with our Services, we collect the following types of information:
          </div>
          <div className={styles.info}>
            <ul className={styles.roman}>
              <li>Identifiers: Name, username and date of birth.</li>
              <li>Contact Information: Email address, mailing address, and phone number.</li>
              <li>Transaction Information: Bank account number, IFSC code, PAN number, records of payments made, and amounts paid.</li>
              <li>Technical Information: IP address, device identifiers, operating system and version, and browsing activity.</li>
              <li>Usage Information: Features used, duration of use, and interaction with our Services.</li>
              <li>Account Information: Username, password, preferences, feedback, and survey responses.</li>
              <li>Marketing and Communications Information: Communication preferences and marketing consents.</li>
            </ul>
          </div>

          <h4 className={cn("h4", styles.title)}>2. How We Collect Your Personal Information</h4>
          <div className={styles.info}>
          We collect your information through:
          </div>
          <div className={styles.info}>
            <ul className={styles.roman}>
              <li>Direct Interactions: When you register for an account, make payments, participate in contests, or communicate with us.</li>
              <li>Automated Technologies: We collect technical and usage information automatically as you interact with our Services.</li>
            </ul>
          </div>

          <h4 className={cn("h4", styles.title)}>3. Usage of the Information Collected</h4>
          <div className={styles.info}>
            BillDrive does not disclose your personal information to any third party without your
            express permission. However, by registering on our Website or App, you grant us
            permission to use and share your personal information as stated in this Privacy
            Policy. We may need to disclose your information when required by law. We use your
            personal information in the following ways.
          </div>
          <div className={styles.info}>
            <ul className={styles.roman}>
              <li>Provide and improve our Services.</li>
              <li>Process transactions and initiate refunds.</li>
              <li>Verify your identity and ensure security.</li>
              <li>Communicate updates, offers, and promotions.</li>
              <li>Analyze usage trends and improve user experience.</li>
              <li>Comply with legal obligations.</li>
            </ul>
          </div>

          <h4 className={cn("h4", styles.title)}>4. Data Security</h4>
          <div className={styles.info}>We implement robust security measures, including encryption and regular security audits, to protect your personal information from unauthorized access, use, or disclosure. Only authorized personnel with a business need to know can access your data, and they are subject to strict confidentiality obligations.</div>
          
          <h4 className={cn("h4", styles.title)}>6. Data Retention</h4>
          <div className={styles.info}>We retain your personal information only as long as necessary to fulfill the purposes for which it was collected, including legal, accounting, or reporting requirements. Once the data is no longer needed, we securely delete or anonymize it.</div>
          
          <h4 className={cn("h4", styles.title)}>7. Cookies</h4>
          <div className={styles.info}>
            When you visit our Website or use our App, cookies may be left in your computer or
            phone. A cookie is a small text file that uniquely identifies your browser and which is
            assigned by the servers of billdrive.in. It may be used to personalize your experience
            on BillDrive. Additionally, it may be used for authentication and
            security purposes. A cookie in no way gives us access to your computer or any
            information about you other than the data you choose to share with us.
          </div>
          <div className={styles.info}>
            You can choose to accept or decline cookies. Most Web browsers automatically
            accept cookies, but you can usually modify your browser setting to decline cookies if
            you wish. This may prevent you from taking full advantage of our Products and
            Services.
          </div>
          
          <h4 className={cn("h4", styles.title)}>8. Your Consent</h4>
          <div className={styles.info}>
            By registering on our Website or App, you give your express consent for the
            collection and use of your personal information by BillDrive. From time to time, we may change our methods of collecting information
            and modify our Privacy Policy. We will post such changes on this page so that you
            are always aware of what information we collect, how we use it, and under what
            circumstances we disclose it. Make sure that you stay updated on our Privacy Policy
            revisions.
          </div>
          <div className={styles.info}>
            BillDrive asserts that this Privacy Policy is only a description of its operation regarding
            User information. This Privacy Policy does not create any legal rights in your favour
            or in favour of any other person, group or organization. BillDrive reserves the right to
            change this Privacy Policy at any time without prior notice.
          </div>

          <h4 className={cn("h4", styles.title)}>9. Changes to our privacy policy</h4>
          <div className={styles.info}>
          We may update this Privacy Policy periodically. We will notify you of any significant changes and obtain your consent if required by law. Your continued use of our Services after any modifications constitutes your acceptance of the updated policy.
          </div>

          <h4 className={cn("h4", styles.title)}>10. How to Contact Us</h4>
          <div className={styles.info}>
            If you have any questions or wish to register a complaint in relation to this Privacy Policy or the manner in which your personal information is used by us, please contact us by any of the following means:
          </div>
          <div className={styles.info}>
            <ul >
              <li>BillDrive
              </li>
              <li>BillDrive Technology Private Limited
              </li>
              <li>4/769, Kumarpuram, Annur Road, Mettupalayam,
              </li>
              <li>Coimbatore, Tamil Nadu, India, 641301
              </li>
              <li>or <a target="_" href="mailto:support@billdrive.in">support@billdrive.in</a>
              </li>
              <li>Phone no: 8610757645
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
