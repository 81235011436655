import React from "react";
import cn from "classnames";
import styles from "./Desc.module.sass";
import ScrollParallax from "../../../components/ScrollParallax";
import Trust from "../Trust";
import Features from "../Features";
import pstyles from "../../../components/Page/Page.module.sass";

const items = [
  {
    title: "Mobile Recharge",
    content:
      "We at BillDrive offer online prepaid mobile recharges and mobile bill payments for all major telecom operators. We try to cover all the talk time and data plans for every operator, to provide an effortless process to select a best suitable plan while saving your time and money. You can now recharge and pay bills online comfortably across all telecom operators using UPI at BillDrive.",
  },
  {
    title: "Online Bill Payment",
    content:
      "BillDrive offers seamless Online Bill Payments facility for most of your utility services like DTH, landline, electricity, water, gas and data card. At BillDrive, you can now browse through a list of available plans and select the best plan as per your suitability. BillDrive saves your effort, time and energy by providing you with the best plan for online bill payments or prepaid mobile recharges.",
  },
];

const RDesc = () => {
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>         
          <div className={styles.col}>
            <h4 className={cn("h4", styles.title)}>What We Do</h4>
            <div className={styles.info}>
            BillDrive provide an online digital solution for prepaid mobile recharge and bill payment related queries. Whether it is your prepaid recharge, postpaid bill payment, DTH, electricity, landline, gas, water or broadband, we have a platform which covers everything.
            </div>           
            <div className={styles.list}>
              {items.map((x, index) => (
                <ScrollParallax className={styles.item} key={index}>
                  <div className={styles.category}>{x.title}</div>
                  <div className={styles.content}>{x.content}</div>
                </ScrollParallax>
              ))}
            </div>           
           
            <Features />  
            <h4 className={cn("h4", styles.title)}>Why Should You Use <span className={pstyles.txttick11}>BillDrive ?</span></h4>
            <div className={styles.infowhy}>
            BillDrive aims to provide reliable utility services through its digital platform with an easy to use interface and user convenience. We make sure to provide you with a seamless experience for any mobile recharge or bill payment, bringing you back every time for all your utility needs.
            </div>   
            <Trust />       
          </div>
        </div>
      </div>
    </div>
  );
};

export default RDesc;
