import React from "react";
import cn from "classnames";
import styles from "./Desc.module.sass";

const Team = () => {

  return (
    <div className={cn("sectionprivacy", styles.sectionprivacy)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("containerhead", styles.containerhead)}>
          <div className={cn("stage-small", styles.stage)}>
            Getting started with
          </div>
          <h2 className={cn("h2", styles.titlehead)}>Account Deletion Request</h2>
        </div>
        <div className={styles.top}>
          <div className={styles.info}>
          We respect your right to manage your data and provide a straightforward way to delete your account and all associated data from our app. If you wish to delete your account, please follow the instructions below:
          </div>
         
          <h4 className={cn("h4", styles.title)}>How to Request Account Deletion</h4>
          <div className={styles.info}>
          1. <b>Open the App:</b>
          </div>
          <div className={styles.info}>
            <ul className={styles.roman}>
              <li>Launch the app and navigate to the My Info menu.</li>
            </ul>
          </div>
          <div className={styles.info}>
          2. <b>Navigate to Security:</b>
          </div>
          <div className={styles.info}>
            <ul className={styles.roman}>
              <li>Select the Security section.</li>
            </ul>
          </div>
          <div className={styles.info}>
          3. <b>Choose Delete My Data and Account:</b>
          </div>
          <div className={styles.info}>
            <ul className={styles.roman}>
              <li>Click on Delete Account.</li>
            </ul>
          </div>
          <div className={styles.info}>
          4. <b>Confirm Your Request:</b>
          </div>
          <div className={styles.info}>
            <ul className={styles.roman}>
              <li>Follow the prompts to confirm that you want to delete your account and associated data.</li>
            </ul>
          </div>
          <div className={styles.info}>
          Your account will be deleted, and most associated data will be removed immediately. For any questions or additional support, please contact us at support@billdrive.in.
          </div>

          <h4 className={cn("h4", styles.title)}>Data Deletion Information</h4>
          <div className={styles.info}>
          <b>Data Deleted:</b>
          </div>
          <div className={styles.info}>
          Upon deletion of your account, the following data will be removed:
          </div>
          <div className={styles.info}>
            <ul className={styles.roman}>
              <li>Personal identification information (e.g., profile data, balance amount, earned tickets and rewards)</li>
              <li>Usage data and activity logs</li>
              <li>Any other data associated with your account, except for certain transactional records</li>
            </ul>
          </div>
          <div className={styles.info}>
          <b>Data Retention:</b>
          </div>
          <div className={styles.info}>
          While most data will be deleted immediately, some data will be retained for accounting and legal purposes, including:
          </div>
          <div className={styles.info}>
            <ul className={styles.roman}>
              <li>Transaction Records: Data related to completed transactions or withdrawals will be retained for financial accounting and compliance.</li>
              <li>Complaint Records: Information related to complaints or disputes will be retained as required by law or for resolution purposes.</li>
            </ul>
          </div>

          <div className={styles.info}>
          <b>Retention Period:</b>
          </div>
          <div className={styles.info}>
          Data retained for accounting or legal reasons will be stored for the minimum period required by law. Once this period expires, the data will be permanently deleted.
          </div>
         

          <h4 className={cn("h4", styles.title)}>Need Help?</h4>         
          <div className={styles.info}>
          If you have any issues or need further assistance with the deletion process, please contact our support team at support@billdrive.in.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
