import React from "react";
import Desc from "./Desc";
import { Helmet, HelmetProvider } from "react-helmet-async";
const RechargeTerms = () => {

  return (
    <HelmetProvider>
      <Helmet>
        <title>Terms & Conditions | BillDrive</title>
        <meta property="title" content="Terms & Conditions | BillDrive" /> 
        <meta name="description" content="We suggest you to read our Terms & Conditions carefully and understand them well. The Terms & Conditions of Billdrive are listed below and included in the medium of reference that can be accessed by clicking on the related links. You should make sure that you have read and understood our Agreement, including our Terms & Conditions, well before becoming a member of BillDrive." />
        <meta property="og:title" content="Terms & Conditions | BillDrive" />
        <meta name="og:description" content="We suggest you to read our Terms & Conditions carefully and understand them well. The Terms & Conditions of Billdrive are listed below and included in the medium of reference that can be accessed by clicking on the related links. You should make sure that you have read and understood our Agreement, including our Terms & Conditions, well before becoming a member of BillDrive." />
        <meta property="og:url" content="https://billdrive.in/terms-conditions" />
        <meta property="twitter:title" content="Terms & Conditions  | BillDrive" />
        <meta name="twitter:description" content="We suggest you to read our Terms & Conditions carefully and understand them well. The Terms & Conditions of Billdrive are listed below and included in the medium of reference that can be accessed by clicking on the related links. You should make sure that you have read and understood our Agreement, including our Terms & Conditions, well before becoming a member of BillDrive." />
      </Helmet>
      <Desc />
    </HelmetProvider>
  );
}; 

export default RechargeTerms;
