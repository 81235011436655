import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import styles from "./Page.module.sass";
import Header from "../HomeHeaderRechargeFAQ";
import Footer from "../Footer";

const HomePage = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if(!pathname.includes("faq")){
      window.scrollTo(0, 0);
      clearAllBodyScrollLocks();
    }
  }, [pathname]);

  return (
    <div className={styles.page}>
      <Header />
        <div className={styles.inner}>{children}</div>
      <Footer />
    </div>
  );
};

export default HomePage;
