import React from "react";
import Breadcrumbsimage from "./Breadcrumbsimage";
import Desc from "./Desc";
import { Helmet, HelmetProvider } from "react-helmet-async";

const ContactUs = () => {

  return (
    <HelmetProvider>
    <Helmet>
        <title>Contact Us | BillDrive</title>
        <meta property="title" content="Contact Us | BillDrive" /> 
        <meta name="description" content="BillDrive Contact Informations" />
        <meta property="og:title" content="Contact Us | BillDrive" />
        <meta name="og:description" content="BillDrive Contact Informations" />
        <meta property="og:url" content="https://billdrive.in/contact-us" />
        <meta property="twitter:title" content="Contact Us | BillDrive" />
        <meta name="twitter:description" content="BillDrive Contact Informations" />
      </Helmet>
      <Breadcrumbsimage />
      <Desc />
    </HelmetProvider>
  );
};

export default ContactUs;
