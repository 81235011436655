import React from "react";

import RDesc from "./RDesc";
import Info from "./Info";
import SDesc from "./SDesc";
import Shopping from "./Shopping";
import Offer from "../../components/Offer";
import Clients from "../../components/Clients";
import { Helmet, HelmetProvider } from "react-helmet-async";


const RechargeHome = () => {

  return (
    <HelmetProvider>
      <Helmet>
        <title>BillDrive - Mobile Recharge & Utility Bill Payment</title>
        <meta property="title" content="BillDrive - Mobile Recharge & Utility Bill Payment. Get More Rewards" /> 
        <meta name="description" content="Mobile Recharge, Utility Bill Payment, Insurance, and Shopping Cashback: Multiply your savings with cashback rewards on every transaction. Secure your future, pay bills, shop smart, and get rewarded." />
        <meta property="og:title" content="BillDrive - Mobile Recharge & Utility Bill Payment. Get More Rewards" />
        <meta name="og:description" content="Mobile Recharge, Utility Bill Payment, Insurance, and Shopping Cashback: Multiply your savings with cashback rewards on every transaction. Secure your future, pay bills, shop smart, and get rewarded." />
        <meta property="og:url" content="https://billdrive.in/" />
        <meta property="twitter:title" content="BillDrive - Mobile Recharge & Utility Bill Payment. Get More Rewards" />
        <meta name="twitter:description" content="Mobile Recharge, Utility Bill Payment, Insurance, and Shopping Cashback: Multiply your savings with cashback rewards on every transaction. Secure your future, pay bills, shop smart, and get rewarded." />
      </Helmet>
      <Clients/>    
      <RDesc />  
      <Info />
      <Shopping />    
      <SDesc/>
    
      <Offer className="section-border-top" />
    </HelmetProvider>
  );
};

export default RechargeHome;
