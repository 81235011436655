import React from "react";
import cn from "classnames";
import styles from "./SDesc.module.sass";
import pstyles from "../../../components/Page/Page.module.sass";

const Shopping = ({ className }) => {
  return (
    <div className={cn(className, styles.section, styles.sbtn)}>      
      <div >
        <img src="https://billdriveassets.s3.ap-south-1.amazonaws.com/images/web/shopping.png" alt="Shopping"/>       
      </div>
      <div className={styles.head}>
          <div className={cn("stage-small", styles.stage)}>
            Explore our network of 100+ merchants
          </div>
          <div className={cn("h2", styles.title)}>
            Shop online with <span className={pstyles.txttick11}>BillDrive</span>, and get more cashback
          </div>
          {/* <div className={cn(className, styles.btns, styles.sbtn)}>
            <Link className={cn("button", styles.button)} to={{  pathname: "https://shop.billdrive.in/" }} target="_blank" rel="noopener noreferrer">
              See All Merchants 
            </Link>
          </div> */}
        </div>
    </div>
  );
};

export default Shopping;
