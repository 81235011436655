import React, {useState, useEffect} from "react";
import { useNavigate , useLocation, useParams } from "react-router-dom";
import cn from "classnames";
import styles from "./Breadcrumbs.module.sass";
import data from "../../../mocks/data";
import Image from "../../Image";

const Breadcrumbs = () => {
  const [title, setTitle] = useState("BillDrive Recharge & Bill Payments");
  const history = useNavigate();
  let { pathname } = useLocation();
  let { category } = useParams();

  useEffect(() => {
    var billdrivetab = document.getElementsByClassName("billdrivetab");
    if (billdrivetab) {
        for (var i = 0; i < billdrivetab.length; ++i) {
          if (billdrivetab[i].classList.contains(styles.active)) {
            billdrivetab[i].classList.remove(styles.active);
          }
        }
    }
    if(!pathname.includes("faq")){      
      setTitle("");
    }else{

      var x = data.all_faq.find((x) => x.url === category);
      if(x !== undefined){
        var selectedId = "billdrivetab" + data.all_faq.indexOf(x);
        var selectedCategory = document.getElementById(selectedId);
        if (selectedCategory) {
          selectedCategory.classList.add(styles.active);
        }
        setTitle( x.title + " FAQ");
      }
    }
  }, [pathname]);


  function onClick (x, selectedId) {
   
    var billdrivetab = document.getElementsByClassName("billdrivetab");
    if (billdrivetab) {
      for (var i = 0; i < billdrivetab.length; ++i) {
        if (billdrivetab[i].classList.contains(styles.active)) {
          billdrivetab[i].classList.remove(styles.active);
        }
      }
    }
    var selectedCategory = document.getElementById(selectedId);
    if (selectedCategory) {
      selectedCategory.classList.add(styles.active);
    }
    setTitle(x.title);
    history("/faq/" + x.url);
  }

  return (
    <div className={styles.breadcrumbs}>
      <div className={cn("container", styles.container, "containerc", styles.containerc)}>
        <div className={styles.wrap}>
          <h3 className={cn("h3", styles.htitle)}>{title.length === 0 ? "Recharge and Pay Bills Safely From Home" : title }</h3>
          <h6 className={cn("h6", styles.subtitle)}>{"Login to pay your bills"}</h6>
          <div className={cn("billdriveresponsive", styles.billdriveresponsive)}>
          <div className={cn("billdrivetabs", styles.billdrivetabs)}>
            {
              data.all_faq.map((x, key) => (
                   
                <div key={key} id={"billdrivetab" + key} className={cn("billdrivetab", styles.billdrivetab)} onClick={(e) => onClick(x, "billdrivetab" + key)}>
                                
                      <div className={cn(styles.category)}>
                        <Image
                          className={styles.pic}
                          src={x.image_src}
                          alt={x.name}
                        />
                      </div>
                      <p className={cn(styles.categoryname)}>{x.name}</p>
                  
                </div>
              
              ))
            }           
          </div>
        </div>
        </div>       
      </div>
    </div>
  );
};

export default Breadcrumbs;
