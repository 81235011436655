import React from "react";
import cn from "classnames";
import styles from "./Intro.module.sass";
import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  "Interoperable",
  "Cost-effective",
  "Accessible",
  "Complaint Management",
  "Clearing & Settlement",
  "Standardisation"
];

const Intro = ({ scrollToRef }) => {
  return (
    <React.Fragment>
    <div className={styles.section} ref={scrollToRef}>
      <div >
        <div className={styles.gallery}>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              src="https://billdriveassets.s3.ap-south-1.amazonaws.com/images/web/utility-current.png"
              alt="Feature"
            />
          </ScrollParallax>
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h4", styles.title)}>
            Key Features
          </h2>
          <div className={styles.text}>
          Bharat BillPay a One-stop access: Bharat BillPay has multiple modes of payment and provides instant confirmation of payment via an SMS or receipt. Bharat BillPay offers myriad bill collection categories like electricity, telecom, DTH, gas, water bills etc. through a single window. In future biller, categories may be expanded to include insurance premium, mutual funds, school fees, institution fees, credit cards, local taxes, invoice payments, etc. An effective mechanism for handling consumer complaints has also been put in place to support consumer regarding any bill related problems in Bharat BillPay.
          </div>
          <ul className={styles.list}>
            {items.map((x, index) => (
              <li className={styles.item} key={index}>
                {x}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    </React.Fragment>
  );
};

export default Intro;
