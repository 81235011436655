import React from "react";
import cn from "classnames";
import styles from "./Clients.module.sass";
import ScrollParallax from "../ScrollParallax";

const list = [
  {
    title: "airtel",
    url: "/images/content/airtel.png",
  },
  {
    title: "jio",
    url: "/images/content/jio.png",
  },
  {
    title: "vi",
    url: "/images/content/vi.png",
  },
  {
    title: "bsnl",
    url: "/images/content/bsnl.png",
  },
  {
    title: "dishtv",
    url: "/images/content/dishtv.png",
  },  {
    title: "sun",
    url: "/images/content/sun.png",
  },
];

const Clients = () => {
  return (
    <div className={styles.clients}>
      <div className={cn("container", styles.container)}>
        <ScrollParallax className={styles.title}>
          More then 20K Billers
        </ScrollParallax>
        <div className={styles.list}>
          {list.map((x, index) => (
            <div className={styles.logo} key={index}>
              <img src={x.url} alt={x.title} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Clients;
