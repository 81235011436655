import React from "react";
import cn from "classnames";
import styles from "./Intro.module.sass";
import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  "Secure and reliable infrastructure with high system availability.",
  "Instant payment updation",
  "Real-time transactions as well as assisted registrations support.",
  "Compliant with BBPS and RBI regulations"
];

const Intro = ({ scrollToRef }) => {
  return (
    <React.Fragment>
    <div className={styles.section} ref={scrollToRef}>
      <div >
        <div className={styles.gallery}>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              src="https://billdriveassets.s3.ap-south-1.amazonaws.com/images/web/utility-bill-mobile.png"
              alt="Feature"
            />
          </ScrollParallax>
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h4", styles.title)}>
            Features
          </h2>
          <div className={styles.text}>
            Access to a large base of 20,000+ billers across 20+ categories and growing.
          </div>
          <ul className={styles.list}>
            {items.map((x, index) => (
              <li className={styles.item} key={index}>
                {x}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    </React.Fragment>
  );
};

export default Intro;
