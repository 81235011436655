import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import styles from "./Page.module.sass";
import Header from "../HeaderRecharge";
import Footer from "../Footer";

const Page = ({ children }) => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, [pathname]);

  return (
    <div className={styles.page}>
      {!search.includes("noheader") ? <Header /> : ""}
      <div className={styles.inner}>{children}</div>
      {!search.includes("noheader") ? <Footer /> : ""}
    </div>
  );
};

export default Page;
