import React from "react";
import cn from "classnames";
import styles from "./Intro.module.sass";
import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  "No charges applicable on recharge transactions",
  "Recharge any prepaid mobile and DTH",
  "Easy, hassle-free and safe",
  "Instant recharge and confirmation from operator",
  "24/7 Friendly Customer Care assistance",
];

const Intro = ({ scrollToRef }) => {
  return (
    <React.Fragment>
    <div className={styles.section} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallery}>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              src="https://billdriveassets.s3.ap-south-1.amazonaws.com/images/web/b-sub-pay.png"
              alt="billpay"
            />
          </ScrollParallax>
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h4", styles.title)}>
          Key Benefits
          </h2>
          <ul className={styles.list}>
            {items.map((x, index) => (
              <li className={styles.item} key={index}>
                {x}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>    
    </React.Fragment>
  );
};

export default Intro;
