import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import Image from "../Image";
import Dbutton from "../Dbutton";
import RDesc from "./RDesc";
const navLinks = [ 
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Recharge & Bill Payments",
    url: "/recharge-bill-pay",
  },
  {
    title: "Shopping",
    url: "/shopping",
  },
  {
    title: "Insurance",
    url: "/insurance",
  },
  {
    title: "Partner Program",
    url: "/partner",
  }
];

const HomeHeaders = (props) => {
  const [visibleNav, setVisibleNav] = useState(false);

  return (
    <> 
      <header className={styles.header}>
        <div className={cn("container", styles.container)}>
          <Link
            className={styles.logo}
            to="/"
            onClick={() => setVisibleNav(false)}
          >
            <Image
              className={styles.pic}
              src="/images/logo-dark.svg"
              srcDark="/images/logo-light.svg"
              alt="BillDrive"
            />
          </Link>
          <div className={cn(styles.wrap, { [styles.active]: visibleNav })}>
            <nav className={styles.nav}>
              {navLinks.map((x, index) =>            
                   <div  key={index}>
                   {x.url.includes("https") ?              
                  <NavLink
                    className={styles.link}
                    activeclassname={styles.active}
                    to={{pathname: x.url}} target="_blank"
                    key={index}
                    onClick={() => setVisibleNav(false)}
                  >
                    {x.title}
                  </NavLink>
                  : <NavLink
                  className={styles.link}
                  activeclassname={styles.active}
                  to={x.url}
                  key={index}
                  onClick={() => setVisibleNav(false)}
                >
                  {x.title}
                </NavLink>}  
                </div>   
              )}
            </nav>
          </div>
          
          <Dbutton stylebtn={styles.headerdownload}/>
          <button
            className={cn(styles.burger, { [styles.active]: visibleNav })}
            onClick={() => setVisibleNav(!visibleNav)}
          ></button>
        </div>
      </header>
      <RDesc headername = {props.headername}/>
    </>
  );
};

export default HomeHeaders;
