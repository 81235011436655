import React from "react";
import PartnerHero from "./PartnerHero";
import Story from "./Story";
import Breadcrumbsimage from "./Breadcrumbsimage";
import Info from "./Info/index";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Partner = () => {

  return ( 
    <HelmetProvider>
    <Helmet>
        <title>Partner Program</title>
        <meta property="title" content="Partner Program" /> 
        <meta name="description" content="BillDrive - Mobile Recharge, Utility Bill Payments, Offers & Cashback Deals | Get More Rewards. Join Now!" />
        <meta property="og:title" content="Partner Program" />
        <meta name="og:description" content="BillDrive - Mobile Recharge, Utility Bill Payments, Offers & Cashback Deals | Get More Rewards. Join Now!" />
        <meta property="og:url" content="https://billdrive.in/about-us" />
        <meta property="twitter:title" content="Partner Program" />
        <meta name="twitter:description" content="BillDrive - Mobile Recharge, Utility Bill Payments, Offers & Cashback Deals | Get More Rewards. Join Now!" />
      </Helmet>     
      <Breadcrumbsimage/>
      <Story />
      <Info/>
      <PartnerHero />
    </HelmetProvider>
  );
};

export default Partner;
