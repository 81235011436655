import React from "react";
import Desc from "./Desc";
import { Helmet, HelmetProvider } from "react-helmet-async";
const AccountDeletion = () => {

  return (
    <HelmetProvider>
      <Helmet>
        <title>Account Deletion Instructions</title>
        <meta property="title" content="Account Deletion Instructions" /> 
        <meta name="description" content="Account Deletion Instructions" />
        <meta property="og:title" content="Account Deletion Instructions" />
        <meta name="og:description" content="BillDrive Account Deletion Instructions." />
        <meta property="og:url" content="https://billdrive.in/delete-my-account" />
        <meta property="twitter:title" content="Account Deletion Instructions" />
        <meta name="twitter:description" content="BillDrive Account Deletion Instructions." />
      </Helmet>
      <Desc />
    </HelmetProvider>
  );
};

export default AccountDeletion;
