import React from "react";
import cn from "classnames";
import Image from "../Image";
import styles from "./Intro.module.sass";

const Dbutton = ({ stylebtn }) => {

  return (
    <React.Fragment >
    <span
        className={cn(styles.headerdownload, styles.headerpadding, stylebtn)}>
          <a href="https://play.google.com/store/apps/details?id=com.creativeinfix.billdrive" target="_blank" rel="noreferrer">
        <Image
          className={styles.pic}
          src="/images/play-store.png"
          alt="BillDrive"
          height="55"
        /></a></span>
      &nbsp;
      {/* <span className={cn("button-stroke button-small", styles.button)}>
          <a href="https://app.billdrive.in" >Login</a></span> */}
    </React.Fragment>
  );
};

export default Dbutton;
