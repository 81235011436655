import React from "react";
import cn from "classnames";
import styles from "./Desc.module.sass";
import Features from "../Features";

const RDesc = () => {
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>         
          <div className={styles.col}>
            <h4 className={cn("h4", styles.title)}>Shop, Save, Earn: The Cashback Advantage</h4>
            <div className={styles.info}>
            In a world where every penny counts, the Cashback Advantage offers you a unique opportunity to make the most of your shopping endeavors. It's a simple concept with incredible benefits: you shop, you save, and you earn cashback rewards on every purchase.
            </div>     
            <Features />  
          </div>
        </div>
      </div>
    </div>
  );
};

export default RDesc;
