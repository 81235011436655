import React from "react";
import cn from "classnames";
import styles from "./Desc.module.sass";
import Plan from "../Plan";

const Team = () => {

  const points = [{
    title: "Normal Points",
    options : [
      {
        title: "Type of Points",
        items: [
          {
            title: "Being a part of the starting 11",
          },
          {
            title: "Every run scored",
          },
          {
            title: "Every wicket taken (excluding run out)",
          },
          {
            title: "Catch taken",
          },
          {
            title: "Caught & Bowled",
          },
          {
            title: "Stumping/ Run Out (direct)",
          },
          {
            title: "Run Out (Thrower/Catcher)",
          },
          {
            title: "Dismissal for a Duck (only for batsmen, wicket-keepers and all-rounders)",
          },
        ],
      },
    ],  
    data: [
      {
        title: "T20",
        options: [
          "4", "1", "25", "8", "33", "12", "6/6", "-2", 
        ]
      }, 
      {
        title: "ODI",
        options: [
          "4", "1", "25", "8", "33", "12", "6/6", "-3", 
        ]
      }, 
      {
        title: "Test",
        options: [
          "4", "1", "16", "8", "24", "12", "6/6", "-4", 
        ]
      }, 
      {
        title: "T10",
        options: [
          "4", "1", "25", "8", "33", "12", "6/6", "-2", 
        ]
      }, 
    ]
 },{
  title: "Bonus Points",
  options : [
    {
      title: "Type of Points",
      items: [
        {
          title: "Every boundary hit",
        },
        {
          title: "Every six-hit",
        },
        {
          title: "Century (100 runs scored by a batsman in a single innings)",
        },
        {
          title: "Bonus (LBW / Bowled)",
        },
        {
          title: "3 Catch Bonus",
        },
        {
          title: "Maiden Over",
        },
        {
          title: "4 wickets",
        },
        {
          title: "5 wickets",
        },
        {
          title: "2 wickets",
        },
        {
          title: "3 wickets",
        },
        {
          title: "30 runs scored by a batsman in a single innings",
        },
        {
          title: "50 runs scored by a batsman in a single innings",
        }
      ],
    },
  ],  
  data: [
    {
      title: "T20",
      options: [
        "1", "2", "8", "16", "8", "4", "12", "8", "16", "NA", "4", "4", "NA",
      ]
    }, 
    {
      title: "ODI",
      options: [
        "1", "2", "4", "8", "8", "4", "4", "4", "8", "NA", "NA", "NA", "NA",
      ]
    }, 
    {
      title: "Test",
      options: [
        "1", "2", "4", "8", "8", "NA", "NA", "4", "8", "NA", "NA", "NA", "NA",
      ]
    }, 
    {
      title: "T10",
      options: [
        "1", "2", "16", "NA", "8", "4", "16", "NA", "NA", "8", "16", "8", "16",
      ]
    }, 
  ]
},{
  title: "Economy Rate",
  options : [
    {
      title: "Type of Points",
      items: [
        {
          title: "Minimum overs bowled by player to be applicable",
        },
        {
          title: "Below 5 runs per over",
        },
        {
          title: "Between 5 and 5.99 runs per over",
        },
        {
          title: "Between 6 and 7 runs per over",
        },
        {
          title: "Between 10 and 11 runs per over",
        },
        {
          title: "Between 11.01 and 12 runs per over",
        },
        {
          title: "Above 12 runs per over",
        },
        {
          title: "Below 2.5 runs per over",
        },
        {
          title: "Between 2.5 and 3.49 runs per over",
        },
        {
          title: "Between 3.5 and 4.5 runs per over",
        },
        {
          title: "Between 7 and 8 runs per over",
        },
        {
          title: "Between 8.01 and 9 runs per over",
        },
        {
          title: "Above 9 runs per over",
        },
        {
          title: "Below 7 runs per over",
        },
        {
          title: "Between 7 and 7.99 runs per over",
        },
        {
          title: "Between 8 and 9 runs per over",
        },
        {
          title: "Between 14 and 15 runs per over",
        },
        {
          title: "Between 15.01 and 16 runs per over",
        },
        {
          title: "Above 16 runs per over",
        }
      ],
    },
  ],  
  data: [
    {
      title: "T20",
      options: [
        "2 Overs", "6", "4", "2", "-2", "-4", "-6", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA"
      ]
    }, 
    {
      title: "ODI",
      options: [
        "5 Overs", "NA", "NA", "NA", "NA", "NA", "NA", "6", "4", "2", "-2", "-4", "-6", "NA", "NA", "NA", "NA", "NA", "NA",
      ]
    }, 
    {
      title: "Test",
      options: [
        "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", 
      ]
    }, 
    {
      title: "T10",
      options: [
        "1 Overs", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "6", "4", "2", "-2", "-4", "-6",
      ]
    }, 
  ]
},{
  title: "Strike Rate (except Bowlers)",
  options : [
    {
      title: "Type of Points",
      items: [
        {
          title: "Minimum balls faced by a player to be applicable",
        },
        {
          title: "Between 60 and 70 runs per 100 balls",
        },
        {
          title: "Between 50 and 59.99 runs per 100 balls",
        },
        {
          title: "Below 50 runs per 100 balls",
        },
        {
          title: "Between 130 and 150 runs per 100 balls",
        },
        {
          title: "Between 150.01 and 170 runs per 100 balls",
        },
        {
          title: "Above 170 runs per 100 balls",
        },
        {
          title: "Between 40 and 50 runs per 100 balls",
        },
        {
          title: "Between 30 and 39.99 runs per 100 balls",
        },
        {
          title: "Below 30 runs per 100 balls",
        },
        {
          title: "Between 100 and 120 runs per 100 balls",
        },
        {
          title: "Between 120.01 and 140 runs per 100 balls",
        },
        {
          title: "Above 140 runs per 100 balls",
        },
        {
          title: "Between 70 and 80 runs per 100 balls",
        },
        {
          title: "Between 60 and 69.99 runs per 100 balls",
        },
        {
          title: "Below 60 runs per 100 balls",
        },
        {
          title: "Between 150 and 170 runs per 100 balls",
        },
        {
          title: "Between 170.01 and 190 runs per 100 balls",
        },
        {
          title: "Over 190 runs per 100 balls",
        }
      ],
    },
  ],  
  data: [
    {
      title: "T20",
      options: [
        "10 Overs", "-2", "-4", "-6", "2", "4", "6", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA"
      ]
    }, 
    {
      title: "ODI",
      options: [
        "20 Overs", "NA", "NA", "NA", "NA", "NA", "NA", "-2", "-4", "-6", "2", "4", "6", "NA", "NA", "NA", "NA", "NA", "NA",
      ]
    }, 
    {
      title: "Test",
      options: [
        "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", 
      ]
    }, 
    {
      title: "T10",
      options: [
        "5 Overs", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "NA", "-2", "-4", "-6", "2", "4", "6",
      ]
    }, 
  ]
}];

  return (
    <div className={cn("sectionprivacy", styles.sectionprivacy)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("containerhead", styles.containerhead)}>
          <div className={cn("stage-small", styles.stage)}>
            Here’s How your Team Earn Points
          </div>
          <h2 className={cn("h2", styles.titlehead)}>Giveaway Contest - Point System</h2>
        </div>
        <div className={styles.top}>
          {points.map((item, index) => (
                <div className={styles.containerpoints} key={index}>
                    <Plan points={item} />                        
                </div>
              ))}
          <h4 className={cn("h4", styles.title)}>Note</h4>
          <div className={styles.info}>
            <ul className={styles.roman}>
              <li>The cricketer you choose to be your BD Cricket Team’s Captain will receive 2 times the points.
              </li>
              <li>The Vice-Captain will receive 1.5 times the points for his performance.
              </li>
              <li>Substitutes on the field will not be awarded points for any contribution they make.
              </li>             
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
