import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Icon from "../Icon";
import Image from "../Image";

const menu = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Recharge & Bill Payments",
    url: "/recharge-bill-pay",
  },
  {
    title: "Shopping",
    url: "/shopping",
  },
  {
    title: "Insurance",
    url: "/insurance",
  }
];

const partner = [
  {
    title: "Partner Program",
    url: "/partner",
  }
];

const company = [

  {
    title: "FAQ's",
    url: "/faq/prepaid-recharge"
  },
  {
    title: "About Us",
    url: "/about-us",
  }
];

const help = [
  {
    title: "Contact Us",
    url: "/contact-us",
  }
];

const legal = [
  {
    title: "Refund Policy",
    url: "/refund-policy",
  },
  {
    title: "Ship and Delivery Policy",
    url: "/ship-delivery-Policy",
  },
  {
    title: "Privacy Policy",
    url: "/privacy-policy",
  },
  {
    title: "Terms & Conditions",
    url: "/terms-conditions",
  },
  {
    title: "Account Deletion",
    url: "/delete-my-account",
  }
];

const socials = [{
  title: "facebook",
  size: "16",
  url: "https://www.facebook.com/infipeofficial/",
},
{
  title: "twitter",
  size: "18",
  url: "https://twitter.com/infipeofficial",
},
{
  title: "instagram",
  size: "16",
  url: "https://www.instagram.com/infipeofficial",
}];

const Footer = () => {
  const [visible, setVisible] = useState(false);
  const [visibleI, setVisibleI] = useState(false);

  return (
    <footer className={styles.footer}>
      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <div className={styles.col}>
            <div className={styles.box}>
              <Link className={styles.logo} to="/">
                <Image
                  className={styles.pic}
                  src="/images/footer.png"
                  alt="BillDrive"
                />
              </Link>
            </div>
            <div className={cn(styles.item, { [styles.active]: visible })}>
              <div
                className={styles.category}
                onClick={() => setVisible(!visible)}
              >
                Menu
                <Icon name="arrow-bottom" size="9" />
              </div>
              <div className={styles.menu}>
                <div className={styles.linkhead}>GENERAL</div>
                {menu.map((x, index) => (
                  <div key={index} className={styles.link}>
                    {x.url.includes("https") ?
                      <NavLink
                        className={styles.link}
                        activeclassname={styles.active}
                        to={{ pathname: x.url }} target="_blank"
                        key={index}
                      >
                        {x.title}
                      </NavLink>
                      : <NavLink
                        className={styles.link}
                        activeclassname={styles.active}
                        to={x.url}
                        key={index}
                      >
                        {x.title}
                      </NavLink>
                    }</div>
                ))}
                <div className={styles.linkhead2}>Our Product</div>
                <div className={styles.link}>
                  <a className={styles.link}
                    activeclassname={styles.active}
                    href="https://infipe.com/"
                    target="_blank"
                    rel="noreferrer"
                  >InfiPe
                  </a>
                </div>
                <div className={styles.linkhead2}>BECOME A PARTNER</div>
                {partner.map((x, index) => (
                  <div key={index} className={styles.link}>
                    {x.url.includes("https") ?
                      <NavLink
                        className={styles.link}
                        activeclassname={styles.active}
                        to={{ pathname: x.url }} target="_blank"
                        key={index}
                      >
                        {x.title}
                      </NavLink>
                      : <NavLink
                        className={styles.link}
                        activeclassname={styles.active}
                        to={x.url}
                        key={index}
                      >
                        {x.title}
                      </NavLink>
                    }</div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={cn(styles.item, { [styles.active]: visibleI })}>
              <div
                className={styles.category}
                onClick={() => setVisibleI(!visibleI)}
              >
                Information
                <Icon name="arrow-bottom" size="9" />
              </div>
              <div className={styles.menu}>
                <div className={styles.linkhead}>COMPANY</div>
                {company.map((x, index) => (
                  <NavLink
                    className={styles.link}
                    activeclassname={styles.active}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                ))}
                <div className={styles.linkhead2}>LEGAL</div>
                {legal.map((x, index) => (
                  <NavLink
                    className={styles.link}
                    activeclassname={styles.active}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                ))}
                <div className={styles.linkhead2}>HELP & SUPPORT</div>
                {help.map((x, index) => (
                  <NavLink
                    className={styles.link}
                    activeclassname={styles.active}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                ))}
              </div>

            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.category}>
              <Image
                className={styles.pic}
                src="https://billdriveassets.s3.ap-south-1.amazonaws.com/images/web/bbps.png"
                srcDark="https://billdriveassets.s3.ap-south-1.amazonaws.com/images/web/bbps.png"
                alt="BillDrive"
              />
              {/* <Image
              className={styles.piccertificate}
              src="/images/tick11.jpg"
              srcDark="/images/tick11.jpg"
              alt="BillDrive"
            /> */}
            </div>
            <div className={styles.category}>Regd. Office Address</div>
            <div className={styles.info}>
              <p >4/769, Kumarpuram, Annur Road, Mettupalayam, Coimbatore, Tamil Nadu, India, 641301</p>
              <p><span className={styles.linkhead2}>CIN:</span> <span className={styles.link}>U82990TZ2023PTC028144</span> </p>
              <p><span className={styles.linkhead2}>GST Reg No:</span> <span className={styles.link}>33AALCB5250H1ZT</span></p>
              <p><span className={styles.linkhead2}>PAN:</span> <span className={styles.link}>AALCB5250H</span></p>
            </div>
            <div className={styles.category}>Get in Touch</div>
            <div className={styles.info}>
              <a href="mailto:support@billdrive.in">support@billdrive.in</a>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.foot}>
        <div className={cn("container", styles.container)}>
          <div className={styles.copyright}>
            Copyright © 2024 BillDrive Technology PVT LTD. All rights reserved
          </div>
          <div className={styles.socials}>
            {socials.map((x, index) => (
              <a
                className={styles.social}
                href={x.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                <Icon name={x.title} size={x.size} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
