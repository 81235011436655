import React from "react";
import Breadcrumbsimage from "./Breadcrumbsimage";
import InfoRight from "./InfoRight";
import InfoLeft from "./InfoLeft";
import Story from "./Story";
import { Helmet, HelmetProvider } from "react-helmet-async";

const About = () => {
 
  return (
    <HelmetProvider>
    <Helmet>
        <title>About Us | BillDrive</title>
        <meta property="title" content="About Us | BillDrive" /> 
        <meta name="description" content="Mobile Recharge, Utility Bill Payment, Insurance, and Shopping Cashback: Multiply your savings with cashback rewards on every transaction. Secure your future, pay bills, shop smart, and get rewarded." />
        <meta property="og:title" content="About Us | BillDrive" />
        <meta name="og:description" content="Mobile Recharge, Utility Bill Payment, Insurance, and Shopping Cashback: Multiply your savings with cashback rewards on every transaction. Secure your future, pay bills, shop smart, and get rewarded." />
        <meta property="og:url" content="https://billdrive.in/about-us" />
        <meta property="twitter:title" content="About Us | BillDrive" />
        <meta name="twitter:description" content="Mobile Recharge, Utility Bill Payment, Insurance, and Shopping Cashback: Multiply your savings with cashback rewards on every transaction. Secure your future, pay bills, shop smart, and get rewarded." />
      </Helmet>
      <Breadcrumbsimage />      
      <Story />
      <InfoRight />
      <InfoLeft />
    </HelmetProvider>
  );
};

export default About;
