import React from "react";
import cn from "classnames";
import styles from "./Desc.module.sass";
import Features from "../Features";

const RDesc = () => {
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>         
          <div className={styles.col}>
            <h4 className={cn("h4", styles.title)}>Secure your future</h4>
            <div className={styles.coldesc}>
              <div className={styles.info}>
              When you invest in insurance coverage, you not only protect yourself and your loved ones but also unlock the potential for significant cashback earnings. It's a smart way to ensure your financial well-being while enjoying the added perk of getting money back. With our insurance cashback program, you'll see your savings grow as you safeguard your future. Don't miss out on this opportunity to make the most of your insurance investment!.
              </div>     
              <div className={styles.info}>
              At BillDrive, we value transparency and want to ensure that our users are fully informed about our affiliate relationship with Acko Insurance. Please take a moment to read the following disclaimer
              </div> 
              <div className={styles.info}>
              BillDrive has entered into an affiliate partnership with Acko Insurance. This means that we may earn a commission when users purchase insurance policies from Acko through the links or promotional materials provided on the BillDrive platform.
              </div> 
            </div>
            <Features />  
          </div>
        </div>
      </div>
    </div>
  );
};

export default RDesc;
