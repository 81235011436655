import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import FAQHomePage from "./components/FAQHomePage";
import HomePage from "./components/HomePage";
import RechargeHome from "./screens/RechargeHome";
import RechargeTerms from "./screens/RechargeTerms";
import RechargePolicy from "./screens/RechargePolicy";
import AccountDeletion from "./screens/AccountDeletion";
import ShipPolicy from "./screens/ShipPolicy";
import Partner from "./screens/Partner";
import Points from "./screens/Points";

import NotFound from "./screens/NotFound";
import BillPay from "./screens/BillPay";
import Shopping from "./screens/Shopping";
import Insurance from "./screens/Insurance";

import About from "./screens/About";
import ContactUs from "./screens/Contact";
import Faq from "./screens/Faq";
import Privacy from "./screens/Privacy";
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  const jsonData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "BillDrive Technology Private Limited",
    "url": "https://www.billdrive.in",
    "sameAs": [
      "https://www.facebook.com/infipeofficial",
      "https://twitter.com/infipeofficial",
      "https://www.instagram.com/infipeofficial",
      "https://www.youtube.com/@infipe",
      "https://infipe.com"
    ]
  };

  return (
    <HelmetProvider>
    <Router>
       <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(jsonData)}
        </script>
      </Helmet>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <HomePage headername = "home">
              <RechargeHome />
            </HomePage>
          }
        />
        <Route
          exact
          path="/recharge-bill-pay"
          element={
            <HomePage  headername = "billpay">
              <BillPay />
            </HomePage>
          }
        />
        <Route
          exact
          path="/shopping"
          element={
            <HomePage  headername = "shopping">
              <Shopping />
            </HomePage>
          }
        />
        <Route
          exact
          path="/insurance"
          element={
            <HomePage  headername = "insurance">
              <Insurance />
            </HomePage>
          }
        />
        <Route
          exact
          path="/about-us"
          element={
            <Page>
              <About />
            </Page>
          }
        />        
        <Route
          exact
          path="/contact-us"
          element={
            <Page>
              <ContactUs />
            </Page>
          }
        />    
        <Route
          exact
          path="/faq/:category"
          element={
            <FAQHomePage>
              <Faq />
            </FAQHomePage>
          }
        />
        <Route
          exact
          path="/partner"
          element={
            <Page>
              <Partner />
            </Page>
          }
        />
        <Route
          exact
          path="/points"
          element={
            <Page>
              <Points />
            </Page>
          }
        />
        <Route
          exact
          path="/privacy-policy"
          element={
            <Page>
              <Privacy />
            </Page>
          }
        />
        <Route
          exact
          path="/refund-policy"
          element={
            <Page>
              <RechargePolicy />
            </Page>
          }
        />
        <Route
          exact
          path="/delete-my-account"
          element={
            <Page>
              <AccountDeletion />
            </Page>
          }
        />
        <Route
          exact
          path="/ship-delivery-Policy"
          element={
            <Page>
              <ShipPolicy />
            </Page>
          }
        />
        <Route
          exact
          path="/terms-conditions"
          element={
            <Page>
               <RechargeTerms />
            </Page>
          }
        />
        <Route
          exact
          path="*"
          element={
            <HomePage>
              <NotFound/>
            </HomePage>
          }
        />
      </Routes>
    </Router>
    </HelmetProvider>
  );
}

export default App;
