import React, {useEffect, useRef} from "react";
import cn from "classnames";
import styles from "./SDesc.module.sass";

const SDesc = ({ className }) => {
  const videoRef = useRef(undefined);

  useEffect(() => {
    videoRef.current.defaultMuted = true;
  });

  return (
    <React.Fragment>
      <div className={styles.videopbody}>
          <video className={styles.videop} poster="https://billdriveassets.s3.ap-south-1.amazonaws.com/images/web/home_bbps.png"  ref={videoRef} playsInline={true} loop={true} autoPlay={true} muted>
              <source src="https://billdriveassets.s3.ap-south-1.amazonaws.com/images/web/home_bbps.mp4" type="video/mp4"/>
          </video>          
      </div>      
      <div className={cn(className, styles.section)}/>
    </React.Fragment>
  );
};

export default SDesc;
