import React from "react";
import cn from "classnames";
import styles from "./RDesc.module.sass";
import pstyles from "../../../components/Page/Page.module.sass";

const items = [
  "One app for all your bill payments",
  "Pay whenever you like",
  "99.999% Server Uptime"
];

const billItems = [
  "Accessibility",
  "Time Savings",
  "User-Friendly Interface",
];

const shopItems = [
  "Save on Everyday Expenses",
  "Track Your Savings",
  "Exclusive Offers",
  "Enhance Your Shopping Experience"
];

const insuranceItems = [
  "Double Benefits",
  "Money Back on Premiums",
  "Rewarding Loyalty",
  "Enhanced Value"
];

const RDesc = (props) => {

  return (
    <React.Fragment>
      {props.headername === "home" ?
        <div className={styles.section}>
          <div className={cn("container", styles.container)}>
            <div className={styles.wrap}>
              <h2 className={cn("h2", styles.title)}>
                Recharge and Pay Bills Safely From <span className={pstyles.txttick11}>Home</span>
              </h2>
              <div className={styles.infotext}>
                Pay your utility bills and recharge your accounts with 1 tap, Keep transacting to get more rewards on every transaction.
              </div>
              <ul className={styles.list}>
                {items.map((x, index) => (
                  <li className={styles.item} key={index}>
                    {x}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        : props.headername === "billpay" ?
        <div className={styles.sectionbillpay}>
           <div className={cn("container", styles.container)}>
            <div className={styles.wrap}>
              <h2 className={cn("h2", styles.title)}>
                Earn Exciting Rewards While Paying <span className={pstyles.txttick11}>Bills!</span>
              </h2>
              <div className={styles.infotext}>
               Enjoy the convenience of managing your finances from home while reaping the benefits of fantastic rewards. It's a win-win!.
              </div>
              <ul className={styles.list}>
                {billItems.map((x, index) => (
                  <li className={styles.item} key={index}>
                    {x}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        :  props.headername === "shopping" ?
        <div className={styles.sectionshopping}>
           <div className={cn("container", styles.container)}>
            <div className={styles.wrap}>
              <h2 className={cn("h2", styles.title)}>
                Shop Smarter and Save More with Cashback <span className={pstyles.txttick11}>Rewards!</span>
              </h2>
              <div className={styles.infotext}>
              Shop till you drop and get cashback with every purchase! Our shopping cashback program is your ticket to extra savings on every shopping adventure.
              </div>
              <ul className={styles.list}>
                {shopItems.map((x, index) => (
                  <li className={styles.item} key={index}>
                    {x}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        :  props.headername === "insurance" ?
        <div className={styles.sectioninsurance}>
           <div className={cn("container", styles.container)}>
            <div className={styles.wrap}>
              <h2 className={cn("h2", styles.title)}>
                Maximize Your Insurance Benefits with Cashback <span className={pstyles.txttick11}>Rewards!</span>
              </h2>
              <div className={styles.infotext}>
              Secure your future while enjoying cashback rewards with our insurance cashback program! 
              </div>
              <ul className={styles.list}>
                {insuranceItems.map((x, index) => (
                  <li className={styles.item} key={index}>
                    {x}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        : ""}
    </React.Fragment>
  );
};

export default RDesc;
