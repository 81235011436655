import React from "react";
import cn from "classnames";
import styles from "./Offer.module.sass";
import pstyles from "../Page/Page.module.sass";
import Dbutton from "../Dbutton";

const Offer = ({ className }) => {
  return (
    <div className={cn(className, styles.section)}>
      <div className={cn(styles.sbg, "container", styles.container)}>
        <div className={cn("stage", styles.stage)}>
          So, what are you waiting for
        </div>
        <h2 className={cn("h1", styles.title)}>
          <span className={pstyles.txttick11}>Shop, save, earn</span> unlock the Cashback Advantage today!
        </h2>
        <div className={styles.text}>
          Start shopping with confidence, knowing that every purchase brings you one step closer to financial success.
        </div>
        <div >
        <Dbutton stylebtn={styles.btnr}/>
        </div>
      </div>
    </div>
  );
};

export default Offer;
