import React from "react";
import cn from "classnames";
import pstyles from "../../../components/Page/Page.module.sass";
import styles from "./Story.module.sass";

const Story = () => {
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
          <h2 className={cn("h2", styles.title)}>For Youtuber and content creator's, Earn by BillDrive Partner Program</h2>
            <h2 className={cn("h2", styles.title)}><span className={pstyles.txttick11}>You’ll be in great company!</span></h2>
            <div className={styles.info}>
              At BillDrive, we believe that collaboration is the key to success. Our Partner Program is designed to foster strong, mutually beneficial relationships with businesses, organizations, and individuals who share our vision and values.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
