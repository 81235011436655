import React from "react";

import RDesc from "./RDesc";
import Info from "./Info";
import Subheader from "./Subheader";
import { Helmet, HelmetProvider } from "react-helmet-async";


const RechargeHome = () => {

  return (
    <HelmetProvider>
      <Helmet>
        <title>BillDrive - Mobile Recharge & Utility Bill Payment</title>
        <meta property="title" content="BillDrive - Mobile Recharge & Utility Bill Payment. Get More Rewards" /> 
        <meta name="description" content="Get more rewards while managing your finances. Enjoy cashback, discounts, and exclusive offers with every transaction, making your payments even more rewarding." />
        <meta property="og:title" content="BillDrive - Mobile Recharge & Utility Bill Payment. Get More Rewards" />
        <meta name="og:description" content="Get more rewards while managing your finances. Enjoy cashback, discounts, and exclusive offers with every transaction, making your payments even more rewarding." />
        <meta property="og:url" content="https://billdrive.in/" />
        <meta property="twitter:title" content="BillDrive - Mobile Recharge & Utility Bill Payment. Get More Rewards" />
        <meta name="twitter:description" content="Get more rewards while managing your finances. Enjoy cashback, discounts, and exclusive offers with every transaction, making your payments even more rewarding." />
      </Helmet>  
      <RDesc />  
      <Subheader/>
      <Info />  
    </HelmetProvider>
  );
};

export default RechargeHome;
