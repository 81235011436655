import React from "react";
import Desc from "./Desc";
import { Helmet, HelmetProvider } from "react-helmet-async";
const RechargePolicy = () => {

  return (
    <HelmetProvider>
      <Helmet>
        <title>Refund Policy | BillDrive</title>
        <meta property="title" content="Refund Policy | BillDrive" /> 
        <meta name="description" content="BillDrive Refund Policy Information." />
        <meta property="og:title" content="Refund Policy | BillDrive" />
        <meta name="og:description" content="BillDrive Refund Policy Information." />
        <meta property="og:url" content="https://billdrive.in/refund-policy" />
        <meta property="twitter:title" content="Refund Policy | BillDrive" />
        <meta name="twitter:description" content="BillDrive Refund Policy Information." />
      </Helmet>
      <Desc />
    </HelmetProvider>
  );
};

export default RechargePolicy;
