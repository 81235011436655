import React from "react";
import cn from "classnames";
import styles from "./Intro.module.sass";
import ScrollParallax from "../../../components/ScrollParallax";


const Intro = ({ scrollToRef }) => {
  return (
    <React.Fragment>
      <div className={styles.section} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.galleryleft}>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              src="https://billdriveassets.s3.ap-south-1.amazonaws.com/images/web/security-sev.png"
              alt="security"
            />
          </ScrollParallax>
        </div>
        <div className={styles.wrapright}>
          <h2 className={cn("h4", styles.title)}>
          Security
          </h2>
          <div className={styles.text}>
          We prioritize the security and privacy of our users' information. We employ robust security measures and encryption protocols to ensure that every transaction made through our platform is safe and protected. 
          </div>
          <div className={styles.text}>
          Our users can have peace of mind knowing that their personal and financial data is handled with utmost care.
          </div>
        </div>
      </div>
    </div>
    <div className={styles.section} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallery}>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              src="https://billdriveassets.s3.ap-south-1.amazonaws.com/images/web/bill-pay.png"
              alt="billpay"
            />
          </ScrollParallax>
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h4", styles.title)}>
            Convenience
          </h2>
          <div className={styles.text}>
          We aim to simplify the recharge and bill payment process, offering a user-friendly platform that allows individuals to complete transactions swiftly and effortlessly.  
          </div>
          <div className={styles.text}>
          By eliminating the need to visit multiple platforms or stand in long queues, we empower our users to manage their financial obligations conveniently from anywhere, at any time.
          </div>
        </div>
      </div>
    </div>    
    </React.Fragment>
  );
};

export default Intro;
