import React from "react";
import cn from "classnames";
import styles from "./Desc.module.sass";
import Features from "../Features";

const RDesc = () => {
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>         
          <div className={styles.col}>
            <h4 className={cn("h4", styles.title)}>Bharat Billpay Payment System (BBPS)</h4>
            <div className={styles.info}>
            The Bharat Bill Payments system is a Reserve Bank of India (RBI) conceptualized system driven by National Payments Corporation of India (NPCI). It is a one-stop payment platform for all bills providing an interoperable and accessible “Anytime Anywhere” bill payment service to all customers across India with certainty, reliability and safety of transactions.
            </div>     
            <Features />  
          </div>
        </div>
      </div>
    </div>
  );
};

export default RDesc;
