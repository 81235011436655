import React from "react";
import Desc from "./Desc";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Points = () => {

  return (
    <HelmetProvider>
      <Helmet>
        <title>Points | BillDrive</title>
        <meta property="title" content="Points | BillDrive" /> 
        <meta name="description" content="Mobile Recharge, Utility Bill Payment, Insurance, and Shopping Cashback: Multiply your savings with cashback rewards on every transaction. Secure your future, pay bills, shop smart, and get rewarded." />
        <meta property="og:title" content="Points | BillDrive" />
        <meta name="og:description" content="Mobile Recharge, Utility Bill Payment, Insurance, and Shopping Cashback: Multiply your savings with cashback rewards on every transaction. Secure your future, pay bills, shop smart, and get rewarded." />
        <meta property="og:url" content="https://billdrive.in/points" />
        <meta property="twitter:title" content="Points | BillDrive" />
        <meta name="twitter:description" content="Mobile Recharge, Utility Bill Payment, Insurance, and Shopping Cashback: Multiply your savings with cashback rewards on every transaction. Secure your future, pay bills, shop smart, and get rewarded." />
      </Helmet>
      <Desc />
    </HelmetProvider>
  );
};

export default Points;
