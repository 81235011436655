import React from "react";
import cn from "classnames";
import styles from "./Breadcrumbs.module.sass";
import IconSupport from "../../../components/IconSupport";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1080"
    >
      <path
        fill="#8f8f8f"
        d="M203.1 536.4c-67 362.7 297.1 651 636.7 388.3 594.1-459.5 300-278.3 619-224.7 319 53.6 347.6-481.4 91.5-474.5s-334.8 220.1-602.4 23.7-688.9-15-744.8 287.2z"
        className="color2a94f4 svgShape"
        opacity="0.1"
      ></path>
      <circle
        cx="897.5"
        cy="667.1"
        r="412.9"
        fill="#fcc"
        className="colorcce9ff svgShape"
      ></circle>
      <circle
        cx="439.3"
        cy="515.7"
        r="61.6"
        fill="#545454"
        className="color0b4870 svgShape"
      ></circle>
      <circle
        cx="477.8"
        cy="372.3"
        r="32.6"
        fill="#ff5722"
        className="colorffbe55 svgShape"
      ></circle>
      <circle
        cx="543.1"
        cy="469"
        r="10.8"
        fill="#545454"
        className="color0b4870 svgShape"
      ></circle>
      <defs>
        <path
          id="a"
          fill="#000"
          d="M1310.4 667.1c0 228-184.9 412.9-412.9 412.9S484.7 895.1 484.7 667.1 641.9 45 869.9 45c169.2 0 542.6 4.1 614.2 209.2 24.9 71.4-173.7 354.1-173.7 412.9z"
          className="color000000 svgShape"
        ></path>
      </defs>
      <clipPath id="b">
        <use overflow="visible" xlinkHref="#a"></use>
      </clipPath>
      <g className="color000000 svgShape" clipPath="url(#b)">
        <path
          fill="#545454"
          d="M726.8 264.9s-10.4-83.7 15.8-121.9c34.2-49.7 131.2-63.6 177.5-36.2 51.2 30.3 92 108.5 74.1 177 76.1 21.9 94.3 108.4 40.8 130.6l45.8 98.1c28.5 61-.6 133.9-66 165.4l-117.1 56.4s-216.1 34-258.9-66.1c-42.8-100.1 41-173.1 41-173.1s-68.4-76-35.8-150.1c32.7-74.1 82.8-80.1 82.8-80.1z"
          className="color0b4870 svgShape"
        ></path>
        <path
          fill="#ffe9e0"
          d="M1059.1 551.7s196.4 31.1 249.6 28.8c41.7 2.3 110.4-226 116.4-240 33.3-20.9 41.6-49.5 41.1-65.3-.5-15.8-43-29.1-47.1-21.1 7-20.9 8.6-27.5 10.5-34.7 4-14.9-7.4-26.2-23.7 29.9-16.1 55.3-14.5 46.2-18.4 65.7-26.3 50.5-94.1 168.3-98.7 186.1-87.9-2.1-236.8-36.3-236.8-36.3l7.1 86.9zm-393.3-53.3s-93.1 174.7-111.2 223.5c-18 36.3 169 203.5 179.5 215.6 6.5 39.7 23.7 53.4 38.3 60.5 14.6 7 42.9-24.7 37.2-32.1 16.5 16.2 21.9 20.7 27.7 25.9 12.1 10.6 26.8 5.8-18.3-35.4-44.5-40.7-36.7-34.9-53.1-47.7-36.2-47.5-118.2-164.1-132.7-176.7 35.3-77.7 123.3-195 123.3-195l-90.7-38.6z"
          className="colorffe3ca svgShape"
        ></path>
        <path
          fill="#545454"
          d="M879.1 1080l54.3-235.6 92.5 55.4s7.5 67.8 17.1 155.7c0 0-72.4 26.2-163.9 24.5"
          className="color0b4870 svgShape"
        ></path>
        <path
          fill="#4d4d4d"
          d="M972.4 1072.8c14.8-89.4 26.6-161.4 26.6-161.4s-253.1-35.2-254 34c-.3 21.2 2.2 62 8.1 108.6 133.1 42.8 219.3 18.8 219.3 18.8z"
          className="color0e538c svgShape"
        ></path>
        <path
          fill="#ff5722"
          d="M974.3 617.1c-7.5 17.4 49.4 259 58.9 314.7 0 0-1.2-.2-3.5-.6-24.7-3.7-175.8-23.6-297.9 27.3l-11.2-341.3-.5-12.7s0-12.9 1.2-32.6v-.2c2.8-40.6 11.2-109.1 37.3-153.1 13.5-22.8 31.4-39.1 55.9-41.5 75.9-7.5 75.5 23.6 146 41l114.4 28.4s-12.8 88.7 0 115.1h-79.4c0-.1-13.8 37.9-21.2 55.5z"
          className="colorffbe55 svgShape"
        ></path>
        <path
          fill="#ffe9e0"
          d="M930.7 149.4s69.6 104.4 33.1 155.4C936.5 343 890.7 363 890.7 363v55s-5.5 4.9-15.1 5.4c-16.7.9-61.1-25.9-61.1-25.9V180.9l116.2-31.5z"
          className="colorffe3ca svgShape"
        ></path>
        <path
          fill="#ff5722"
          d="M720.4 631.2s105-121.7 88.3-180.3c-16.7-58.6-96.1-38.5-140.5 29-44.4 67.5-62 90.6-62 90.6s45.7 61.8 114.2 60.7z"
          className="colorffbe55 svgShape"
        ></path>
        <path
          fill="#545454"
          d="M930.5 139.5s5.4 108.7-47 135-12.8 91.1-62.7 139.5c-14.8 14.3-36.8 11.9-53.7 6.9-24.2-7.2-51.9-2-68.8 15.5-1.6 1.7-3.1 3.5-4.3 5.3-64.8-42.8 99.4-280.7 99.4-280.7l137.1-21.5z"
          className="color0b4870 svgShape"
        ></path>
      </g>
      <path
        fill="#ff5722"
        d="M1120.8 733.8c-22.7 35.1-35.9 76.9-35.9 121.8 0 123.9 100.5 224.4 224.4 224.4s224.4-100.5 224.4-224.4-100.5-224.4-224.4-224.4c-54.7 0-104.9 19.6-143.8 52.2l-76.1-20.1 31.4 70.5zm263.4-672.2c0 34-27.6 77.9-61.6 77.9S1261 95.6 1261 61.6 1288.5 0 1322.6 0s61.6 27.6 61.6 61.6z"
        className="colorffbe55 svgShape"
      ></path>
      <path
        fill="#fffcfc"
        d="M1324.5 167.4c-6.3 0-12.2-4.4-17.4-13.1-3.7-6.2-.9-24.9 2.1-44.7.8-5.2 1.5-10.3 2.2-14.9-4.4-13.7-3.4-22.6-1.5-25.2.7-1 1.7-1.3 2.6-.9 1.8.9 2.8 4.7 2.8 10.8 0 4-.5 9.1-1.2 14.8.3 1 .7 2 1.1 3.1 3.6 9.6 7.2 7.5 8.4 6.7 2.9-1.7 5.7-6.5 7.8-12-.8-12.4.1-21.3 1.5-23.9.7-1.4 1.6-1.5 2-1.5 1.4.1 2.1 2 2.3 2.6 1.8 4.8.2 14.9-3.1 23.5 1 14.5 4.4 34.4 12.8 55.3l.4.9-.5.8c-.4.7-9.7 17.2-22 17.4-.1.3-.2.3-.3.3zm-11.3-67.4c-.5 3.3-1 6.7-1.5 10.2-2.6 17.4-5.6 37-2.6 42 4.8 7.9 10 11.9 15.5 11.8 9.5-.2 17.4-12 19.4-15.2-7.3-18.4-10.9-36.4-12.3-50.8-2.1 4.2-4.6 7.6-7.2 9.1-2.4 1.6-7.2 2.6-11.3-7.1zm-1.6-28c-.7 1.3-1.6 6.6.5 15.7.3-3.1.5-5.9.5-8.2 0-4.7-.7-6.8-1-7.5zm23.1-1.2c-.6 2-1.2 7-1.1 14.2 1.6-5.8 2.2-11.4 1.1-14.2z"
        className="colorffffff svgShape"
      ></path>
      <path
        fill="#545454"
        d="M1322.6 175.8c-12.4 0-22.7-9.4-23.7-21.7l-2.1-23.6 6.3 2.4c12.9 4.9 27.1 4.8 39.9-.3l5.3-2.1-2.1 23.6c-1 12.3-11.3 21.7-23.6 21.7z"
        className="color0b4870 svgShape"
      ></path>
      <path
        fill="#ff5722"
        d="M1577.3 179.3c-7.2 17.1-30.4 33.4-47.5 26.2s-21.7-35.1-14.5-52.3c7.2-17.1 26.9-25.2 44.1-18s25.1 26.9 17.9 44.1z"
        className="colorffbe55 svgShape"
      ></path>
      <path
        fill="#fffcfc"
        d="M1524.8 219.9c-3.2-1.3-5.2-4.8-6-10.3-.6-3.9 4.8-12.7 10.5-22 1.5-2.4 2.9-4.8 4.2-7 .7-7.8 3.1-12.1 4.6-13 .6-.4 1.1-.3 1.5.1.7.8.4 3-.9 6-.8 2-2.2 4.5-3.8 7.2 0 .6-.1 1.2-.1 1.8-.2 5.6 2 5.3 2.8 5.2 1.8-.3 4.2-2.1 6.5-4.4 2.2-6.4 4.6-10.7 5.8-11.7.6-.5 1.1-.4 1.3-.3.7.4.6 1.4.6 1.8-.1 2.8-3.1 7.5-6.6 11.2-2.6 7.5-5.1 18.2-5.2 30.5v.5l-.4.3c-.3.3-8.5 6.6-14.7 4.1h-.1zm8.6-36.3c-.9 1.6-1.9 3.2-2.9 4.8-5 8.2-10.6 17.5-10.2 20.6.7 5 2.5 8.1 5.3 9.2 4.8 1.9 11.3-2.4 13-3.6.2-10.8 2.2-20.6 4.6-28.2-1.9 1.7-3.9 2.8-5.5 3.1-1.7.3-4.3-.2-4.3-5.9zm5.1-14.5c-.6.5-2.2 3-3 8 .8-1.5 1.5-2.9 2-4 .9-2.3 1.1-3.5 1-4zm11.9 4.3c-.7.9-2.1 3.3-3.5 6.9 2-2.5 3.5-5.2 3.5-6.9z"
        className="colorffffff svgShape"
      ></path>
      <path
        fill="#545454"
        d="M1522.1 223.7c-6.2-2.6-9.4-9.5-7.4-15.9l4-12.3 2.7 2.5c5.4 5.2 12.6 8.1 20.1 8.3l3.1.1-6 11.4c-3.1 6-10.3 8.5-16.5 5.9z"
        className="color0b4870 svgShape"
      ></path>
      <path
        fill="#ff5722"
        d="M1337.6 277.1c1.2 13.8-8.3 32.6-22.1 33.8-13.8 1.2-26.6-15.5-27.8-29.3s8.9-26 22.7-27.2 25.9 8.9 27.2 22.7z"
        className="colorffbe55 svgShape"
      ></path>
      <path
        fill="#fffcfc"
        d="M1317.2 322.1c-2.6.2-5.1-1.3-7.5-4.7-1.7-2.4-1.3-10.1-.8-18.2.1-2.1.2-4.2.3-6.1-2.3-5.4-2.2-9-1.5-10.1.3-.4.6-.6 1-.5.8.3 1.3 1.8 1.5 4.3.1 1.6.1 3.7 0 6.1l.6 1.2c1.8 3.8 3.2 2.8 3.6 2.4 1.1-.8 2.1-2.8 2.7-5.1-.8-5-.7-8.6-.3-9.7.2-.6.6-.7.8-.7.6 0 .9.7 1 1 .9 1.9.6 6-.4 9.7.9 5.8 3 13.8 7.2 21.9l.2.4-.2.4c-.1.3-3.3 7.3-8.3 7.9.2-.2.2-.2.1-.2zm-7-26.9c-.1 1.3-.2 2.8-.2 4.2-.4 7.1-.9 15.2.5 17.1 2.2 3 4.5 4.5 6.7 4.2 3.8-.4 6.6-5.5 7.3-6.9-3.6-7.2-5.7-14.4-6.9-20.2-.7 1.8-1.6 3.2-2.6 4-.9.8-2.8 1.4-4.8-2.4zm-1.7-11.3c-.3.6-.4 2.8.8 6.3 0-1.3 0-2.4-.1-3.3-.2-1.9-.5-2.7-.7-3zm9.4-1.3c-.2.8-.2 2.9.1 5.8.4-2.4.4-4.7-.1-5.8z"
        className="colorffffff svgShape"
      ></path>
      <path
        fill="#545454"
        d="M1316.8 325.6c-5 .5-9.5-3-10.4-7.9l-1.7-9.5 2.6.7c5.4 1.5 11.2.9 16.2-1.6l2.1-1.1v9.6c0 5.2-3.8 9.4-8.8 9.8z"
        className="color0b4870 svgShape"
      ></path>
    </svg>
  );
}

const Breadcrumbs = () => {
  return (
    <div className={styles.breadcrumbs}>
      <div className={cn("container", styles.container, "containerc", styles.containerc)}>
        <div className={styles.wrap}>
          <div className={styles.warpleft}>
            <div>
              <h2 className={cn("h2", styles.title)}>About Us</h2>
              <div className={styles.info}>We provide online digital solution for users</div>
              <div className={styles.imgsupport}>{IconSupport()}</div>
            </div>
          </div>
          <div className={styles.warpright}>
           {Icon()}
          </div>
        </div>
        <div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
