import React, { useState } from "react";
import cn from "classnames";
import styles from "./Plan.module.sass";

const Plan = (props) => {
  const [more, setMore] = useState([false, false, false]);
  const title = props.points.title;
  const options = props.points.options
  const data = props.points.data

  const renderContent = (content) => {    
    return <div className={styles.minus}>{content}</div>;
  };

  return (
    <div >
      <div >        
        <h4 className={cn("h4", styles.title)}>
        {title}
        </h4>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.body}>
                {options.map((option, index) => (
                  <div className={styles.item} key={index}>
                    <div className={styles.category}>{option.title}</div>
                    {option.items.map((item, index) => (
                      <div className={styles.parameter} key={index}>
                        <div className={styles.label}>{item.title}</div>                        
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            {data.map((type, index) => (

              <div className={styles.col} key={index}>                
                <div className={styles.body}>                  
                  <div
                    className={cn(styles.list, {
                      [styles.visible]: more[index],
                    })}
                  > 
                    {options.map((option, optionIndex) => (
                      <div className={styles.item} key={optionIndex}>
                        <div className={styles.category}>{type.title}</div>
                        {option.items.map((item, itemIndex) => (
                          <div className={styles.parameter} key={itemIndex}>
                            <div className={styles.label}>{item.title}</div>
                            {renderContent(type.options[itemIndex])}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>                 
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
