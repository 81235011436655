const data = {
  all_faq : [
    {
        "title": "Mobile Prepaid Recharge",
        "name": "Mobile Prepaid",
        "category": "Mobile Prepaid",
        "url": "prepaid-recharge",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/prepaid-recharge.png",
        "faq": [
            {
                "qus": "What is the procedure for recharging any prepaid number online?",
                "ans": "Billdrive makes it super simple to recharge your mobile number online. Choose your operator and enter your mobile number. Choose the plan and pay."
            }, {
                "qus": "Is it possible for a Postpaid recharge?",
                "ans": "Yes, you can pay your postpaid bills at billdrive anytime using UPI payment methods."
            }, {
                "qus": "How to find the best plan for me?",
                "ans": "Visit Billdrive and choose your operator. You can find the list of all plans for your mobile operator, and you can check the best plan by simply inserting your mobile number."
            }, {
                "qus": "Is there any offer on general mobile recharge?",
                "ans": "When you recharge your mobile with us, get the best offers."
            }, {
                "qus": "Where can I recharge my prepaid number online?",
                "ans": "Billdrive is a trusted and reliable payment platform that offers excellent phone and email support"
            }
        ]
    },
    {
        "title": "Mobile Postpaid Bill Payment",
        "name": "Mobile Postpaid",
        "category": "Mobile Postpaid",
        "url": "postpaid-bill",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/postpaid-bill.png",
        "faq": [
            {
                "qus": "What is the procedure for recharging any prepaid number online?",
                "ans": "Billdrive makes it super simple to recharge your mobile number online. Choose your operator and enter your mobile number. Choose the plan and pay."
            }, {
                "qus": "Is it possible for a Postpaid recharge?",
                "ans": "Yes, you can pay your postpaid bills at billdrive anytime using UPI payment methods."
            }, {
                "qus": "How to find the best plan for me?",
                "ans": "Visit Billdrive and choose your operator. You can find the list of all plans for your mobile operator, and you can check the best plan by simply inserting your mobile number."
            }, {
                "qus": "Is there any offer on general mobile recharge?",
                "ans": "When you recharge your mobile with us, get the best offers."
            }, {
                "qus": "Where can I recharge my prepaid number online?",
                "ans": "Billdrive is a trusted and reliable payment platform that offers excellent phone and email support"
            }
        ]
    }, {
        "title": "DTH Recharge",
        "name": "DTH Recharge",
        "category": "DTH",
        "url": "dth",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/dth.png",
        "faq": [
            {
                "qus": "How long does it take to recharge DTH ?",
                "ans": "Recharging DTH on Billdrive takes a few seconds. As soon as your transaction is complete."
            }, {
                "qus": "Can Billdrive recharge DTH for every amount ?",
                "ans": "There is no limit to the amount that Billdrive can recharge your DTH. However, we recommend that you check your operator's website to make sure that DTH recharge is compatible with Billdrive."
            }, {
                "qus": "How can I download an invoice for my DTH recharge ?",
                "ans": "Billdrive let you download invoices on the transaction page."
            }
        ]
    }, {
        "title": "Electricity Bill Payment",
        "name": "Electricity",
        "category": "Electricity",
        "url": "electricity",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/electricity.png",
        "faq": [
            {
                "qus": "Is your electricity bill payment limited to any state or is it for all over India?",
                "ans": "No, we have not restricted BillDrive to any one region or state. Rather you can pay your electricity bill at any place in India. We provide you with the option of almost every electricity supplier in India. So that you will not face any kind of problem."
            }, {
                "qus": "After how much time will my electricity bill be successful after making payment on BillDrive?",
                "ans": "As soon as you make your electricity bill payment on BillDrive, we pass your information to your electricity supplier, and then it depends on your supplier how soon they accept your payment and make your bill payment successful. We have reduced our processing time to almost no amount."
            }, {
                "qus": "Do you share any confidential information related to the electricity bill of your users with others?",
                "ans": "No. Not at all, we do not share any type of confidential information with others without your permission in the greed of a small amount of money. It is our responsibility to provide you with an absolutely safe, secure, and private bill payment platform. You can pay with BillDrive without worrying about your security."
            }
        ]
    }, {
        "title": "Credit Card Bill Payment",
        "name": "Credit Card",
        "category": "Credit Card",
        "url": "creditcard",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/creditcard.png",
        "faq": [
            {
                "qus": "Do I need to pay my credit card in full?",
                "ans": "If you can, attempt to maintain the balance on your credit card as low as you can. Ideally, you should pay your credit card statement in full. Your credit score will benefit more if your balance is lower."
            }, {
                "qus": "Does late payment affect my credit Score?",
                "ans": "Yes late payments do affect your credit score"
            }, {
                "qus": "How long does it take to process the credit card bill payment on BillDrive?",
                "ans": "It takes at least 2-3 business days to process the credit card bill payment on BillDrive."
            }, {
                "qus": "When should I pay my credit card bill?",
                "ans": "You should pay your credit card bill before the due date to avoid extra late fee charges."
            }
        ]
    }, {
        "title": "Landline Bill Payment",
        "name": "Landline Postpaid",
        "category": "Landline Postpaid",
        "url": "landline",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/landline.png",
        "faq": [
            {
                "qus": "Why should I choose BillDrive for my landline recharge?",
                "ans": "There is no single reason to use BillDrive to recharge your landline number. You can easily recharge any of your landline numbers from anywhere without wasting much time through this platform. Plus we provide you with a completely secure and secure platform without paying any kind of extra fee."
            }, {
                "qus": "Why should I trust your BillDrive platform?",
                "ans": "You can absolutely trust BillDrive as we keep your recharge absolutely transparent. You can easily recharge any of your landline numbers whenever you want through a completely secure method. Also, BillDrive provides a 24/7 Uptime Server. This means there is no chance to get your money stuck."
            }, {
                "qus": "What is my use in recharging my landline number from your platform?",
                "ans": "With BillDrive you can recharge your landline number in a jiffy without any extra effort. This platform is accessible anytime and from anywhere i.e. you do not have to go anywhere to recharge your number. You can enjoy it anytime by recharging your landline number sitting at home."
            }, {
                "qus": "Can I recharge the landline number of any provider through BillDrive?",
                "ans": "When you recharge your mobile with us, get the best offers."
            }
        ]
    }, {
        "title": "Broadband Postpaid Bill Payment",
        "name": "Broadband Postpaid",
        "category": "Broadband Postpaid",
        "url": "broadband",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/broadband.png",
        "faq": [
            {
                "qus": "How long will it take to complete my Broadband bill payment through BillDrive?",
                "ans": "Unlike offline markets or other online platforms, we provide you with a platform where you do not have to wait even a bit for your broadband bill payment. As soon as you enter all your broadband details and pay for it, we will process your payment within a few seconds or a maximum of a few minutes."
            }, {
                "qus": "Can I rely on the BillDrive in terms of personal card and Payments details?",
                "ans": "Your safety and security are our first responsibility. This is the reason why we provide you with a completely safe and secure platform. BillDrive does not steal any of your personal details without your permission. We do not store your cards and payment details with us."
            }, {
                "qus": "What if my payment fails while paying my broadband bill?",
                "ans": "We have developed our website in such a way that has not failed any of your payments. Our server stays 24/7 Uptime. But even if your payment fails for some reason, your payment will get reflected in your account in 24 hours."
            }
        ]
    }, {
        "title": "Gas Bill Payment",
        "name": "Piped Gas",
        "category": "Gas",
        "url": "piped-gas",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/piped-gas.png",
        "faq": [
            {
                "qus": "How do I calculate my gas bill?",
                "ans": "In order to know your gas bill, a user can note the meter reading and simply send it to their respective operator. The gas bill will be received via registered contact details."
            }, {
                "qus": "How much is your average gas bill?",
                "ans": "The average gas bill of a user depends on the usage and the frequency. To know about the gas bill, one can contact their respective operator for the details."
            }, {
                "qus": "Can a gas leak cause a high gas bill?",
                "ans": "There are possibilities that a gas leak may result in a high gas bill. The bill raised to the end user is as per the usage, if the usage is more, the gas bill will be higher."
            }
        ]
    }, {
        "title": "LPG Gas Booking Online",
        "name": "LPG Gas Booking",
        "category": "LPG Gas",
        "url": "gas",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/gas.png",
        "faq": [
            {
                "qus": "What kind of payment options are available on BillDrive to book a gas cylinder?",
                "ans": "We always ensure that you get all the necessary options of payment on the BillDrive. This is the reason that we have provided several payment options on our platform. You'll get all the necessary payment options for your cylinder booking such as Debit card, credit card, net banking, UPI ids, etc."
            }, {
                "qus": "Will I get the subsidy on my LPG cylinder booking if I book my cylinder through Your platform?",
                "ans": "If you are eligible for any subsidy given by the government then the subsidy amount will be deposited in your bank account within 2 days after booking the cylinder by BillDrive. If it does not happen then you can immediately contact your distributor and Gas Agency."
            }, {
                "qus": "Can I cancel my Cylinder booking?",
                "ans": "If you have not yet paid your cylinder booking then you can cancel your booking absolutely. But if you have made a payment for your cylinder, now you can not cancel your cylinder because your booking details are sent to your distributor with money immediately."
            }
        ]
    }, {
        "title": "Insurance",
        "name": "Insurance",
        "category": "Insurance",
        "url": "insurance",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/insurence.png",
        "faq": [
            {
                "qus": "Make Your Life Insurance Premium Payment Online Securely ?",
                "ans": "All the payments made using the BillDrive website vow full security. You do not have to disclose any private information about yourself to BillDrive. The password will protect the basic transaction information, and you are advised not to share it with anyone."
            }
        ]
    },
    {
        "title": "Water Bill Payment",
        "name": "Water",
        "category": "Water",
        "url": "water",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/water.png",
        "faq": [
            {
                "qus": "Is it Safe to Pay Water Bills Online Through BillDrive ?",
                "ans": "BillDrive offers a safe and secured payment platform where you can make payments."
            }, {
                "qus": "Can we make payment from any bank ?",
                "ans": "Yes, you can pay your water bills from any bank that should be linked to UPI."
            }, {
                "qus": "Will I have to bear any additional charges for the water bill payment through BillDrive ?",
                "ans": "No, we do not charge you any additional charges for our services. It means you do not need to pay any additional charges for your water bill payment. All the information about your payment is written in your bill invoice, which you can check whenever you want."
            }
        ]
    },
    {
        "title": "Fastag Recharge",
        "name": "Fastag",
        "category": "Fastag",
        "url": "fastag",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/fastag.png",
        "faq": [
            {
                "qus": "Is fastag mandatory for all vehicles in India?",
                "ans": "Yes, as per the latest government rules and regulations, Fastag has been made mandatory for every vehicle on the highway all over India. With the help of this, you will no longer have to stop at every toll booth to pay toll tax."
            }, {
                "qus": "How to recharge my fastag account through BillDrive?",
                "ans": "You can recharge your fastag account easily with BillDrive just by tapping on fastag option and choosing the right fastag issuing bank name on our site. You can also read the above-mentioned Steps to complete your fastag recharge."
            }
        ]
    },
    {
        "title": "Loan EMI Payment Online",
        "name": "Loan Repayment",
        "category": "Loan Repayment",
        "url": "loan",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/loan.png",
        "faq": [
            {
                "qus": "The various benefits that we offer for you ?",
                "ans": "We have assisted a lot of people in making their loan EMI payment online. When you try and make the payment from our interface you would see to your utter delight that we have made it very easy and simple for you. You would now be able to make the payments quite easily in just a few steps."
            }
        ]
    },
    {
        "title": "Education Fees",
        "name": "Education Fees",
        "category": "Education Fees",
        "url": "education-fee",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/education-fee.png",
        "faq": [
            {
                "qus": "Is it Safe to Pay Bills Online Through BillDrive ?",
                "ans": "BillDrive offers a safe and secured payment platform where you can make payments."
            }, {
                "qus": "Can we make payment from any bank ?",
                "ans": "Yes, you can pay your Education Fees from any bank that should be linked to UPI."
            }, {
                "qus": "Will I have to bear any additional charges for the bill payment through BillDrive ?",
                "ans": "No, we do not charge you any additional charges for our services. It means you do not need to pay any additional charges for your bill payment. All the information about your payment is written in your bill invoice, which you can check whenever you want."
            }
        ]
    },{
        "title": "Municipal Taxes",
        "name": "Municipal Taxes",
        "category": "Municipal Taxes",
        "url": "municipal-taxes",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/municipal-services.png",
        "faq": [
            {
                "qus": "Is it Safe to Pay Bills Online Through BillDrive ?",
                "ans": "BillDrive offers a safe and secured payment platform where you can make payments."
            }, {
                "qus": "Can we make payment from any bank ?",
                "ans": "Yes, you can pay your Municipal Taxes from any bank that should be linked to UPI."
            }, {
                "qus": "Will I have to bear any additional charges for the bill payment through BillDrive ?",
                "ans": "No, we do not charge you any additional charges for our services. It means you do not need to pay any additional charges for your bill payment. All the information about your payment is written in your bill invoice, which you can check whenever you want."
            }
        ]
    },{
        "title": "Housing Society",
        "name": "Housing Society",
        "category": "Housing Society",
        "url": "housing-society",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/housing-society.png",
        "faq": [
            {
                "qus": "Is it Safe to Pay Bills Online Through BillDrive ?",
                "ans": "BillDrive offers a safe and secured payment platform where you can make payments."
            }, {
                "qus": "Can we make payment from any bank ?",
                "ans": "Yes, you can pay your Housing Society bills from any bank that should be linked to UPI."
            }, {
                "qus": "Will I have to bear any additional charges for the bill payment through BillDrive ?",
                "ans": "No, we do not charge you any additional charges for our services. It means you do not need to pay any additional charges for your bill payment. All the information about your payment is written in your bill invoice, which you can check whenever you want."
            }
        ]
    },{
        "title": "Subscription",
        "name": "Subscription",
        "category": "Subscription",
        "url": "subscription",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/subscription.png",
        "faq": [
            {
                "qus": "Is it Safe to Pay Bills Online Through BillDrive ?",
                "ans": "BillDrive offers a safe and secured payment platform where you can make payments."
            }, {
                "qus": "Can we make payment from any bank ?",
                "ans": "Yes, you can pay your Subscription bills from any bank that should be linked to UPI."
            }, {
                "qus": "Will I have to bear any additional charges for the bill payment through BillDrive ?",
                "ans": "No, we do not charge you any additional charges for our services. It means you do not need to pay any additional charges for your bill payment. All the information about your payment is written in your bill invoice, which you can check whenever you want."
            }
        ]
    },{
        "title": "Life Insurance",
        "name": "Life Insurance",
        "category": "Life Insurance",
        "url": "life-insurance",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/insurence.png",
        "faq": [
            {
                "qus": "Is it Safe to Pay Bills Online Through BillDrive ?",
                "ans": "BillDrive offers a safe and secured payment platform where you can make payments."
            }, {
                "qus": "Can we make payment from any bank ?",
                "ans": "Yes, you can pay your Life Insurance from any bank that should be linked to UPI."
            }, {
                "qus": "Will I have to bear any additional charges for the bill payment through BillDrive ?",
                "ans": "No, we do not charge you any additional charges for our services. It means you do not need to pay any additional charges for your bill payment. All the information about your payment is written in your bill invoice, which you can check whenever you want."
            }
        ]
    },{
        "title": "Cable TV",
        "name": "Cable TV",
        "category": "Cable TV",
        "url": "cable-tv",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/cable-tv.png",
        "faq": [
            {
                "qus": "Is it Safe to Pay Bills Online Through BillDrive ?",
                "ans": "BillDrive offers a safe and secured payment platform where you can make payments."
            }, {
                "qus": "Can we make payment from any bank ?",
                "ans": "Yes, you can pay your Cable TV bills from any bank that should be linked to UPI."
            }, {
                "qus": "Will I have to bear any additional charges for the bill payment through BillDrive ?",
                "ans": "No, we do not charge you any additional charges for our services. It means you do not need to pay any additional charges for your bill payment. All the information about your payment is written in your bill invoice, which you can check whenever you want."
            }
        ]
    },{
        "title": "Clubs and Associations",
        "name": "Clubs and Associations",
        "category": "Clubs and Associations",
        "url": "clubs-associations",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/clubs-associations.png",
        "faq": [
            {
                "qus": "Is it Safe to Pay Bills Online Through BillDrive ?",
                "ans": "BillDrive offers a safe and secured payment platform where you can make payments."
            }, {
                "qus": "Can we make payment from any bank ?",
                "ans": "Yes, you can pay your Clubs and Associations bills from any bank that should be linked to UPI."
            }, {
                "qus": "Will I have to bear any additional charges for the bill payment through BillDrive ?",
                "ans": "No, we do not charge you any additional charges for our services. It means you do not need to pay any additional charges for your bill payment. All the information about your payment is written in your bill invoice, which you can check whenever you want."
            }
        ]
    },{
        "title": "Hospital and Pathology",
        "name": "Hospital and Pathology",
        "category": "Hospital and Pathology",
        "url": "hospital",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/hospital.png",
        "faq": [
            {
                "qus": "Is it Safe to Pay Bills Online Through BillDrive ?",
                "ans": "BillDrive offers a safe and secured payment platform where you can make payments."
            }, {
                "qus": "Can we make payment from any bank ?",
                "ans": "Yes, you can pay your Hospital bills from any bank that should be linked to UPI."
            }, {
                "qus": "Will I have to bear any additional charges for the bill payment through BillDrive ?",
                "ans": "No, we do not charge you any additional charges for our services. It means you do not need to pay any additional charges for your bill payment. All the information about your payment is written in your bill invoice, which you can check whenever you want."
            }
        ]
    },{
        "title": "Municipal Services",
        "name": "Municipal Services",
        "category": "Municipal Services",
        "url": "municipal-services",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/municipal-services.png",
        "faq": [
            {
                "qus": "Is it Safe to Pay Bills Online Through BillDrive ?",
                "ans": "BillDrive offers a safe and secured payment platform where you can make payments."
            }, {
                "qus": "Can we make payment from any bank ?",
                "ans": "Yes, you can pay your Municipal Services bills from any bank that should be linked to UPI."
            }, {
                "qus": "Will I have to bear any additional charges for the bill payment through BillDrive ?",
                "ans": "No, we do not charge you any additional charges for our services. It means you do not need to pay any additional charges for your bill payment. All the information about your payment is written in your bill invoice, which you can check whenever you want."
            }
        ]
    },{
        "title": "Health Insurance",
        "name": "Health Insurance",
        "category": "health-insurance",
        "url": "health-insurance",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/insurence.png",
        "faq": [
            {
                "qus": "Is it Safe to Pay Bills Online Through BillDrive ?",
                "ans": "BillDrive offers a safe and secured payment platform where you can make payments."
            }, {
                "qus": "Can we make payment from any bank ?",
                "ans": "Yes, you can pay your Health Insurance from any bank that should be linked to UPI."
            }, {
                "qus": "Will I have to bear any additional charges for the bill payment through BillDrive ?",
                "ans": "No, we do not charge you any additional charges for our services. It means you do not need to pay any additional charges for your bill payment. All the information about your payment is written in your bill invoice, which you can check whenever you want."
            }
        ]
    },  {
        "title": "Recurring Deposit",
        "name": "Recurring Deposit",
        "category": "Recurring Deposit",
        "url": "recurring-deposit",
        "image_src": "https://billdriveassets.s3.ap-south-1.amazonaws.com/images/bbps-icon/recurring.png",
        "billerNames": [],
        "billerDetails": [],
        "faq": [
            {
                "qus": "Is it Safe to Pay Bills Online Through BillDrive ?",
                "ans": "BillDrive offers a safe and secured payment platform where you can make payments."
            }, {
                "qus": "Can we make payment from any bank ?",
                "ans": "Yes, you can pay Recurring Deposit from any bank that should be linked to UPI."
            }, {
                "qus": "Will I have to bear any additional charges for the bill payment through BillDrive ?",
                "ans": "No, we do not charge you any additional charges for our services. It means you do not need to pay any additional charges for your bill payment. All the information about your payment is written in your bill invoice, which you can check whenever you want."
            }
        ]
    }
]
};

export default data;