import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import styles from "./Page.module.sass";
import Header from "../HomeHeaderRecharge";
import Footer from "../Footer";

const HomePage = ({ headername, children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if(!pathname.includes("faq")){
      window.scrollTo(0, 0);
      clearAllBodyScrollLocks();
    }
  }, [pathname]);

  return (
    <div className={styles.page}>
      <Header headername = {headername} />
        <div className={styles.inner}>{children}</div>
      <Footer />
    </div>
  );
};

export default HomePage;
