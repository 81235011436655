import React from "react";
import cn from "classnames";
import styles from "./PartnerHero.module.sass";
import pstyles from "../../../components/Page/Page.module.sass";
import ScrollParallax from "../../../components/ScrollParallax";
import { NavLink } from "react-router-dom";

const items = [
  {
    title: "Contact Us",
    content:
      "Please submit a request to join the partner program through the contact form provided",
    color: "#9757D7",
  },
  {
    title: "Share",
    content:
      "Be it online or offline, share your Referral Code where your audience are.",
    color: "#EF466F",
  },
  {
    title: "Earn",
    content:
      "Earn every time your audience pay their bills or purchase a product from their favorite store.",
    color: "#45B26B",
  },
];

const PartnerHero = () => {
  return (
    <div className={cn("section", styles.book)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("stage", styles.stage)}>Partner Program</div>
          </div>
          <div className={styles.col}>
            <h2 className={cn("h2", styles.title)}>
              Become our Partner in Delight
            </h2>
            <div className={styles.info}>
              Partnering with BillDrive can lead to accelerated business growth for you and delight made easy for your customers. It’s a <span className={pstyles.txttick11}>win-win!</span>
            </div>
            <div className={styles.info}>
              It's free, and takes less than a minute, Instantly monetize your audience
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.list}>
              {items.map((x, index) => (
                <ScrollParallax className={styles.item} key={index}> 
                  <div
                    className={styles.number}
                    style={{ backgroundColor: x.color }}
                  >
                    0{index + 1}
                  </div>
                  <div className={styles.subtitle}>{x.title}</div>
                  <div className={styles.content}>{x.content}</div>
                  {x.title === "Contact Us" ?
                    <NavLink
                      className={styles.button}
                      activeclassname={styles.active}
                      to="https://www.app.billdrive.in/partner-join-request" target="_blank"
                      key={index}
                    >
                     Click here to Join Now 
                    </NavLink>: ""}
                </ScrollParallax>
              ))}
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.bg}>
              <img
                srcSet="/images/content/partner.png"
                src="/images/content/partner.png"
                alt="Partner pic"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerHero;
