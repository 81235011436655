import React from "react";
import cn from "classnames";
import styles from "./Intro.module.sass";
import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  "Shop with Confidence",
  "Save More Every Time",
  "Effortless Earnings",
  "Flexible Rewards",
  "Clearing & Settlement",
  "Continuous Benefits"
];

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 500 500"
      xmlSpace="preserve"
    >
      <circle
        cx="91"
        cy="242.4"
        r="24.1"
        fill="#f2c3b0"
        className="colorb0c9f2 svgShape"
      ></circle>
      <circle
        cx="225.5"
        cy="422.8"
        r="12.4"
        fill="#fff2ed"
        className="colorffeded svgShape"
      ></circle>
      <circle
        cx="432.3"
        cy="285.7"
        r="12.4"
        fill="#fff2ed"
        className="colorffeded svgShape"
      ></circle>
      <circle
        cx="247"
        cy="160.8"
        r="12.4"
        fill="#fff2ed"
        className="colorffeded svgShape"
      ></circle>
      <path
        fill="#fff2ed"
        d="M190.2 203.2s-57.8 4.2-52.4 36.4c5.4 32.2-12.4 69.9-28.3 78.4-15.8 8.6-37.9 39 3.8 56.8 41.7 17.9 68.5 1.8 111.4-3.6s45.9 12.5 76.3 14.9c30.4 2.4 49.9-26.2 65.4-27.4 15.5-1.2 21.8 10.9 45.3.3 23.4-10.6 54.4-16.2 33-37.6-21.5-21.5-46.9-28-39.4-43.2s35.4-12 23.5-34.3c-11.9-22.2-41.3-6.8-78.3-16.7-36.9-9.9-40.9-29-65.6-29.4-24.6-.4-20.7 8.7-47.3 7.2s-47.4-1.8-47.4-1.8z"
        className="colorffeded svgShape"
      ></path>
      <path
        fill="#767676"
        d="M384.9 284L404.6 295.4 424.6 283.9 404.5 272.4z"
        className="color767676 svgShape"
        opacity="0.5"
      ></path>
      <path
        fill="#fb9a73"
        d="M388.6 284.4L388.4 265.6 404.6 274.9 404.6 293.7z"
        className="colorfbba73 svgShape"
      ></path>
      <path
        fill="#e47d5d"
        d="M404.6 293.7L420.8 284.3 420.8 265.5 404.6 274.9z"
        className="colore4905d svgShape"
      ></path>
      <path
        fill="#e47d5d"
        d="M388.5 265.6L404.6 274.9 420.8 265.5 404.5 256.2z"
        className="colore4b45d svgShape"
      ></path>
      <path
        fill="#fff0f0"
        d="M395.6 269.7L398.1 271.2 414.3 261.8 411.9 260.3z"
        className="colorf0f8ff svgShape"
      ></path>
      <path
        fill="#fff0f0"
        d="M395.6 269.7L395.6 271.6 398.1 272.9 398.1 271.2z"
        className="colorf0f8ff svgShape"
      ></path>
      <path
        fill="#fff0f0"
        d="M398.7 274.1L398.7 277.8 403.5 280.6 403.5 276.9z"
        className="colorf0f8ff svgShape"
      ></path>
      <path
        fill="#2c2c2c"
        d="M401 276.5L401 276.1 403.1 277.3 403.1 277.7z"
        className="color052554 svgShape"
      ></path>
      <path
        fill="#2c2c2c"
        d="M399.2 276.1L399.2 275.7 403.1 278 403.1 278.3z"
        className="color052554 svgShape"
      ></path>
      <path
        fill="#2c2c2c"
        d="M399.2 276.6L399.2 276.3 403.1 278.5 403.1 278.9z"
        className="color052554 svgShape"
      ></path>
      <path
        fill="#2c2c2c"
        d="M399.2 277.2L399.2 276.8 403.1 279.1 403.1 279.4z"
        className="color052554 svgShape"
      ></path>
      <path
        fill="#767676"
        d="M407.9 297.5L427.7 308.9 447.6 297.4 427.6 286z"
        className="color767676 svgShape"
        opacity="0.5"
      ></path>
      <path
        fill="#fb9a73"
        d="M411.7 297.9L411.5 279.1 427.6 288.4 427.6 307.2z"
        className="colorfbba73 svgShape"
      ></path>
      <path
        fill="#e47d5d"
        d="M427.6 307.2L443.8 297.8 443.8 279 427.6 288.4z"
        className="colore4905d svgShape"
      ></path>
      <path
        fill="#e47d5d"
        d="M411.5 279.1L427.6 288.4 443.8 279 427.5 269.7z"
        className="colore4b45d svgShape"
      ></path>
      <path
        fill="#fff0f0"
        d="M418.6 283.2L421.2 284.7 437.3 275.3 435 273.8z"
        className="colorf0f8ff svgShape"
      ></path>
      <path
        fill="#fff0f0"
        d="M418.6 283.2L418.6 285.1 421.1 286.4 421.2 284.7z"
        className="colorf0f8ff svgShape"
      ></path>
      <path
        fill="#fff0f0"
        d="M421.8 287.6L421.8 291.3 426.6 294.1 426.6 290.4z"
        className="colorf0f8ff svgShape"
      ></path>
      <path
        fill="#2c2c2c"
        d="M424.1 290L424.1 289.6 426.1 290.8 426.1 291.2z"
        className="color052554 svgShape"
      ></path>
      <path
        fill="#2c2c2c"
        d="M422.2 289.6L422.2 289.2 426.1 291.5 426.1 291.8z"
        className="color052554 svgShape"
      ></path>
      <path
        fill="#2c2c2c"
        d="M422.2 290.1L422.2 289.8 426.1 292 426.1 292.4z"
        className="color052554 svgShape"
      ></path>
      <path
        fill="#2c2c2c"
        d="M422.2 290.7L422.2 290.3 426.1 292.6 426.1 292.9z"
        className="color052554 svgShape"
      ></path>
      <path
        fill="#767676"
        d="M126 223.7L150.3 237.8 198.6 210.7 173.9 196.6z"
        className="color767676 svgShape"
        opacity="0.5"
      ></path>
      <linearGradient
        id="SVGID_1_"
        x1="36.045"
        x2="36.636"
        y1="202.116"
        y2="202.116"
        gradientTransform="translate(160.994)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop
          className="stopColorbec4e4 svgShape"
          offset="1"
          stopColor="#e4c9be"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_1_)"
        d="M197 199.3L197 205.4 197.6 204.9 197.6 198.9z"
      ></path>
      <linearGradient
        id="SVGID_2_"
        x1="21.669"
        x2="36.047"
        y1="198.3"
        y2="198.3"
        gradientTransform="translate(160.994)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop
          className="stopColorbec4e4 svgShape"
          offset="1"
          stopColor="#e4c9be"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_2_)"
        d="M197 199.3L197 205.4 182.7 197.3 182.7 191.2z"
      ></path>
      <path
        fill="#ce6442"
        d="M182.7 191.2L197 199.3 197.6 198.9 183.3 190.8z"
        className="colorce4242 svgShape"
      ></path>
      <path
        fill="#262626"
        d="M184.6 209.2c0 1.3.5 2.3 1.2 2.7l2.4 1.4c.8.4.3-.5 1.5-1.2 2.4-1.4 4.3-4.7 4.3-7.4 0-1.3 1.2-1.4.5-1.8l-2.5-1.4c-.8-.4-1.8-.3-3 .3-2.5 1.3-4.4 4.7-4.4 7.4z"
        className="color262626 svgShape"
      ></path>
      <path
        fill="#262626"
        d="M187.1 210.6c0 2.7 1.9 3.8 4.3 2.4s4.3-4.7 4.3-7.4-1.9-3.8-4.3-2.4-4.3 4.7-4.3 7.4z"
        className="color262626 svgShape"
      ></path>
      <radialGradient
        id="SVGID_3_"
        cx="-4150.252"
        cy="207.96"
        r="4.631"
        gradientTransform="matrix(-1 0 0 1 -3959.751 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop offset="1"></stop>
      </radialGradient>
      <path
        fill="url(#SVGID_3_)"
        d="M191.2 203.1c1.1-.7 2.2-.7 2.9-.4-.7-.2-1.6-.1-2.6.5-2.4 1.4-4.3 4.7-4.3 7.4 0 1.4.5 2.4 1.4 2.8-1-.3-1.7-1.3-1.7-2.9 0-2.7 1.9-6.1 4.3-7.4z"
      ></path>
      <path
        fill="#a0a0a0"
        d="M191.4 205.1c-1.5.9-2.8 3-2.8 4.8 0 1.8 1.2 2.5 2.8 1.6 1.5-.9 2.8-3 2.8-4.8 0-1.7-1.3-2.4-2.8-1.6z"
        className="colora0a0a0 svgShape"
      ></path>
      <path
        fill="#7b7b7b"
        d="M190.4 210.9c1.5-.9 2.8-3 2.8-4.8 0-.5-.1-1-.3-1.3.8.1 1.3.8 1.3 1.9 0 1.8-1.2 3.9-2.8 4.8-1.1.6-2 .5-2.4-.3.4.1.9 0 1.4-.3z"
        className="color7b7c7c svgShape"
      ></path>
      <linearGradient
        id="SVGID_4_"
        x1="5165.724"
        x2="5173.021"
        y1="206.141"
        y2="209.925"
        gradientTransform="translate(-4979.429)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColor8770b8 svgShape"
          offset="0"
          stopColor="#949494"
        ></stop>
        <stop
          className="stopColorffffff svgShape"
          offset="1"
          stopColor="#fff"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_4_)"
        d="M189.5 209.7c0 1.1.8 1.5 1.7 1 .9-.5 1.7-1.9 1.7-2.9s-.8-1.5-1.7-1c-.9.5-1.7 1.8-1.7 2.9z"
      ></path>
      <path
        fill="#7b7b7b"
        d="M189.6 209.1c0 .9.6 1.3 1.4.8.8-.5 1.4-1.6 1.4-2.5 0-.9-.6-1.3-1.4-.8-.7.4-1.4 1.5-1.4 2.5z"
        className="color7b7c7c svgShape"
      ></path>
      <path
        fill="#c9c8c7"
        d="M190 209.3c0 .9.6 1.3 1.4.8.8-.5 1.4-1.6 1.4-2.5 0-.9-.6-1.3-1.4-.8-.7.5-1.4 1.6-1.4 2.5z"
        className="colorc9c8c7 svgShape"
      ></path>
      <linearGradient
        id="SVGID_5_"
        x1="-12.829"
        x2="3.524"
        y1="217.75"
        y2="217.75"
        gradientTransform="translate(160.994)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop
          className="stopColorbec4e4 svgShape"
          offset="1"
          stopColor="#e4c9be"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_5_)"
        d="M164.5 219L164.5 225.9 148.2 216.6 148.2 209.6z"
      ></path>
      <linearGradient
        id="SVGID_6_"
        x1="-12.829"
        x2="23.683"
        y1="208.351"
        y2="208.351"
        gradientTransform="translate(160.994)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop
          className="stopColorbec4e4 svgShape"
          offset="1"
          stopColor="#e4c9be"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_6_)"
        d="M148.2 209.6L164.5 219 184.7 208 168.9 197.7z"
      ></path>
      <linearGradient
        id="SVGID_7_"
        x1="3.518"
        x2="24.221"
        y1="216.869"
        y2="216.869"
        gradientTransform="translate(160.994)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop
          className="stopColorbec4e4 svgShape"
          offset="1"
          stopColor="#e4c9be"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_7_)"
        d="M164.5 219v6.9l20.7-12v-5c0-.8-.9-1.4-1.6-.9l-19.1 11z"
      ></path>
      <linearGradient
        id="SVGID_8_"
        x1="7.857"
        x2="36.707"
        y1="202.606"
        y2="202.606"
        gradientTransform="translate(160.994)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop
          className="stopColorbec4e4 svgShape"
          offset="1"
          stopColor="#e4c9be"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_8_)"
        d="M197.7 198.1l-25.9 16.4-2.9-16.8 13.5-6.8c.5-.3 1.1-.3 1.7 0l13.6 7.2z"
      ></path>
      <linearGradient
        id="SVGID_9_"
        x1="5.026"
        x2="36.679"
        y1="208.255"
        y2="208.255"
        gradientTransform="translate(160.994)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop
          className="stopColorbec4e4 svgShape"
          offset="1"
          stopColor="#e4c9be"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_9_)"
        d="M197.6 199.3L197.7 198.5 166 217.2 166 218.1z"
      ></path>
      <path
        fill="#ea663d"
        d="M148.3 204.4L148.3 182.2 167.7 193.6 167.7 215.2z"
        className="coloreaac3d svgShape"
      ></path>
      <path
        fill="#cc5029"
        d="M149 204.1L149 183.5 166.9 194.1 166.9 214z"
        className="colorcc8e29 svgShape"
      ></path>
      <path
        fill="#ea663d"
        d="M198.4 176.1l-30.7 17.5v21.5l30.5-16.6.2-22.4z"
        className="coloreaac3d svgShape"
      ></path>
      <path
        fill="#f96d41"
        d="M148.3 182.2L178.7 165 198.4 176.1 167.7 193.6z"
        className="colorf9be41 svgShape"
      ></path>
      <path
        fill="#e86238"
        d="M150.9 182.2L167.7 192 171.1 190 154.1 180.3z"
        className="colore8a838 svgShape"
      ></path>
      <path
        fill="#e86238"
        d="M154.9 179.9L171.8 189.6 175.2 187.7 158.2 178z"
        className="colore8a838 svgShape"
      ></path>
      <path
        fill="#e86238"
        d="M159 177.6L175.9 187.3 179.2 185.3 162.3 175.7z"
        className="colore8a838 svgShape"
      ></path>
      <path
        fill="#e86238"
        d="M163.1 175.2L179.9 184.9 183.3 183 166.3 173.3z"
        className="colore8a838 svgShape"
      ></path>
      <path
        fill="#e86238"
        d="M167.1 172.9L184 182.6 187.4 180.6 170.4 171z"
        className="colore8a838 svgShape"
      ></path>
      <path
        fill="#e86238"
        d="M171.2 170.5L188.1 180.2 191.4 178.3 174.5 168.6z"
        className="colore8a838 svgShape"
      ></path>
      <path
        fill="#e86238"
        d="M175.3 168.2L192.1 177.9 195.5 176 178.5 166.3z"
        className="colore8a838 svgShape"
      ></path>
      <path
        fill="#cc5029"
        d="M169.1 194.1L169 213.6 172.3 211.7 172.3 192.2z"
        className="colorcc8e29 svgShape"
      ></path>
      <path
        fill="#cc5029"
        d="M173.1 191.7L173 211.3 176.4 209.4 176.4 189.9z"
        className="colorcc8e29 svgShape"
      ></path>
      <path
        fill="#cc5029"
        d="M177.2 189.4L177.1 208.9 180.4 207 180.4 187.6z"
        className="colorcc8e29 svgShape"
      ></path>
      <path
        fill="#cc5029"
        d="M181.2 187.1L181.1 206.6 184.4 204.7 184.4 185.2z"
        className="colorcc8e29 svgShape"
      ></path>
      <path
        fill="#cc5029"
        d="M185.2 184.7L185.1 204.3 188.4 202.3 188.4 182.9z"
        className="colorcc8e29 svgShape"
      ></path>
      <path
        fill="#cc5029"
        d="M189.2 182.4L189.1 201.9 192.5 200 192.5 180.5z"
        className="colorcc8e29 svgShape"
      ></path>
      <path
        fill="#cc5029"
        d="M193.3 180L193.2 199.6 196.5 197.7 196.5 178.2z"
        className="colorcc8e29 svgShape"
      ></path>
      <path
        fill="#262626"
        d="M130.1 206c-.1 0-.1 0-.1-.1l-1-1.4c-.3-.4-.5-.9-.5-1.5v-2.9c0-1.1.8-2.1 1.9-2.4l1.6-.4c.1 0 .2 0 .2.1s0 .2-.1.2l-1.6.4c-.9.2-1.6 1.1-1.6 2.1v2.9c0 .5.1.9.4 1.3l1 1.4c.1.1 0 .2 0 .2-.1.1-.2.1-.2.1z"
        className="color262626 svgShape"
      ></path>
      <linearGradient
        id="SVGID_10_"
        x1="-33.304"
        x2="-31.089"
        y1="201.443"
        y2="201.443"
        gradientTransform="translate(160.994)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop
          className="stopColorbec4e4 svgShape"
          offset="1"
          stopColor="#e4c9be"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_10_)"
        d="M129.2 203.9l-.9-.5c-.4-.2-.7-.8-.7-1.3v-2.6c0-.5.3-.7.7-.5l.9.5c.4.2.7.8.7 1.3v2.6c0 .5-.3.7-.7.5z"
      ></path>
      <linearGradient
        id="SVGID_11_"
        x1="5107.269"
        x2="5109.334"
        y1="201.442"
        y2="201.442"
        gradientTransform="translate(-4979.429)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop
          className="stopColorbec4e4 svgShape"
          offset="1"
          stopColor="#e4c9be"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_11_)"
        d="M129.2 199.5l-.9-.5c-.2-.1-.4-.1-.5 0 .1 0 .2 0 .3.1l.9.5c.4.2.7.8.7 1.3v2.6c0 .2-.1.4-.1.5.2 0 .4-.2.4-.6v-2.6c-.1-.5-.4-1.1-.8-1.3z"
      ></path>
      <linearGradient
        id="SVGID_12_"
        x1="-33.536"
        x2="5.128"
        y1="210.396"
        y2="210.396"
        gradientTransform="translate(160.994)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop
          className="stopColorbec4e4 svgShape"
          offset="1"
          stopColor="#e4c9be"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_12_)"
        d="M146.9 187.4l-13.4 7.8c-1.6.9-2.8 2.5-3.3 4.3l-1.9 7.5c-.6 2.2-.9 4.5-.9 6.8v8.7l19.2 10.8v-7.8c0-2.8.4-5.7 1.3-8.4l2.4-7.7c.5-1.7 1.7-3.1 3.2-4l12.6-7.3-19.2-10.7z"
      ></path>
      <linearGradient
        id="SVGID_13_"
        x1="-14.376"
        x2="5.128"
        y1="215.835"
        y2="215.835"
        gradientTransform="translate(160.994)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop
          className="stopColorbec4e4 svgShape"
          offset="1"
          stopColor="#e4c9be"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_13_)"
        d="M146.6 225.6v7.8l10.3-5.9.8-4.5 8.5-5v-19.7l-12.6 7.4c-1.5.9-2.7 2.3-3.2 4l-2.4 7.7c-1 2.6-1.4 5.4-1.4 8.2z"
      ></path>
      <path
        fill="#3e3e3e"
        d="M151.5 210l-2.4 7.7c-.2.5.4 1 .9.7l7.6-4.4c.5-.3.8-.8.8-1.4v-6.3c0-.5-.5-.8-.9-.5l-4.7 2.8c-.7.2-1.1.7-1.3 1.4zm-5.5 8.5c-.2 0-.4 0-.6-.1l-15.6-8.8c-.7-.4-1.1-1.3-.9-2.1l1.9-7.7c.1-.4.2-.8.4-1.1.1-.2.4-.4.6-.4.1 0 .2 0 .3.1l16.9 9.7c.5.3.7.8.5 1.3l-2.6 8.4c0 .4-.4.7-.9.7z"
        className="color2e324f svgShape"
      ></path>
      <path
        fill="#3e3e3e"
        d="M128.9 207.4l1.9-7.7c.1-.4.2-.8.4-1.1.1-.2.4-.4.6-.4.1 0 .2 0 .3.1L149 208c.6.3-20.3.2-20.1-.6z"
        className="color2e324f svgShape"
      ></path>
      <path
        fill="#262626"
        d="M146.3 217.6c-.2 0-.4-.1-.6-.2l-15.8-9c-.5-.3-.9-.8-1-1.4l-.1.3c-.2.8.2 1.7.9 2.1l15.6 8.8c.2.1.4.1.6.1.5 0 .9-.3 1.1-.8l.2-.5c-.2.5-.5.6-.9.6z"
        className="color262626 svgShape"
      ></path>
      <linearGradient
        id="SVGID_14_"
        x1="-30.011"
        x2="-22.502"
        y1="209.711"
        y2="209.711"
        gradientTransform="translate(160.994)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop
          className="stopColorbec4e4 svgShape"
          offset="1"
          stopColor="#e4c9be"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_14_)"
        d="M138.4 209.3l-4.4-1s-.1-.1-.2 0l-2.6-.6c-.1 0-.2 0-.2.1s0 .2.1.2l2.6.6.8 2.9c0 .1.1.1.2.1s.1-.1.1-.2l-.8-2.7 4.2.9c.1 0 .2-.1.2-.1.1-.1 0-.2 0-.2z"
      ></path>
      <linearGradient
        id="SVGID_15_"
        x1="-23.062"
        x2="-15.553"
        y1="213.598"
        y2="213.598"
        gradientTransform="translate(160.994)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop
          className="stopColorbec4e4 svgShape"
          offset="1"
          stopColor="#e4c9be"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_15_)"
        d="M145.3 213.2l-4.4-1s-.1-.1-.2 0l-2.6-.6c-.1 0-.2 0-.2.1s0 .2.1.2l2.6.6.8 2.9c0 .1.1.1.2.1s.1-.1.1-.2l-.8-2.7 4.2.9c.1 0 .2-.1.2-.1.2-.1.1-.2 0-.2z"
      ></path>
      <linearGradient
        id="SVGID_16_"
        x1="-2.699"
        x2="-0.748"
        y1="214.419"
        y2="214.419"
        gradientTransform="translate(160.994)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop
          className="stopColorbec4e4 svgShape"
          offset="1"
          stopColor="#e4c9be"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_16_)"
        d="M159.8 213.6l-1.1.6c-.2.1-.4.4-.4.7v.1c0 .3.3.5.6.3l1-.6c.2-.1.4-.4.4-.7v-.2c-.1-.2-.3-.4-.5-.2z"
      ></path>
      <g className="color000000 svgShape">
        <path
          fill="#cc9a86"
          d="M159.9 214.7l-1 .6c-.2.1-.5 0-.6-.3v.2c0 .3.3.5.6.3l1-.6c.2-.1.4-.4.4-.7v-.2c-.1.3-.2.5-.4.7z"
          className="colorb086cc svgShape"
        ></path>
      </g>
      <linearGradient
        id="SVGID_17_"
        x1="-3.304"
        x2="5.128"
        y1="220.167"
        y2="220.167"
        gradientTransform="translate(160.994)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop
          className="stopColorbec4e4 svgShape"
          offset="1"
          stopColor="#e4c9be"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_17_)"
        d="M157.8 217.4L166.1 218 157.7 222.9z"
      ></path>
      <linearGradient
        id="SVGID_18_"
        x1="-0.3"
        x2="2.588"
        y1="222.749"
        y2="222.749"
        gradientTransform="translate(160.994)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop
          className="stopColorbec4e4 svgShape"
          offset="1"
          stopColor="#e4c9be"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_18_)"
        d="M163.6 221.7L163.6 225.5 160.7 223.8 160.9 220z"
      ></path>
      <path
        fill="#262626"
        d="M151.9 218.4c-.1 0-.1 0-.2-.1s0-.2.1-.2l1.2-.7c.6-.4 1-1.1 1-1.8v-4.7c0-.7-.4-1.4-.9-1.8l-1.2-.8c-.1-.1-.1-.2 0-.2.1-.1.2-.1.2 0l1.2.8c.7.5 1.1 1.2 1.1 2.1v4.7c0 .9-.5 1.7-1.2 2.1l-1.3.6c.1 0 .1 0 0 0z"
        className="color262626 svgShape"
      ></path>
      <linearGradient
        id="SVGID_19_"
        x1="-7.54"
        x2="-5.324"
        y1="213.369"
        y2="213.369"
        gradientTransform="translate(160.994)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop
          className="stopColorbec4e4 svgShape"
          offset="1"
          stopColor="#e4c9be"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_19_)"
        d="M155 215.8l-.9-.5c-.4-.2-.7-.8-.7-1.3v-2.6c0-.5.3-.7.7-.5l.9.5c.4.2.7.8.7 1.3v2.6c0 .5-.3.7-.7.5z"
      ></path>
      <linearGradient
        id="SVGID_20_"
        x1="5133.034"
        x2="5135.099"
        y1="213.367"
        y2="213.367"
        gradientTransform="translate(-4979.429)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop
          className="stopColorbec4e4 svgShape"
          offset="1"
          stopColor="#e4c9be"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_20_)"
        d="M155 211.4l-.9-.5c-.2-.1-.4-.1-.5 0 .1 0 .2 0 .3.1l.9.5c.4.2.7.8.7 1.3v2.6c0 .2-.1.4-.1.5.2 0 .4-.2.4-.6v-2.6c-.1-.5-.4-1.1-.8-1.3z"
      ></path>
      <linearGradient
        id="SVGID_21_"
        x1="5126.087"
        x2="5122.377"
        y1="226.944"
        y2="228.063"
        gradientTransform="translate(-4979.429)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColor9f79c8 svgShape"
          offset="0"
          stopColor="#a0a0a0"
        ></stop>
        <stop
          className="stopColora580cb svgShape"
          offset="0.137"
          stopColor="#cb9680"
        ></stop>
        <stop
          className="stopColorb492d3 svgShape"
          offset="0.329"
          stopColor="#d3a592"
        ></stop>
        <stop
          className="stopColorcdb0e1 svgShape"
          offset="0.553"
          stopColor="#e1beb0"
        ></stop>
        <stop
          className="stopColord1b4e3 svgShape"
          offset="0.581"
          stopColor="#e3c2b4"
        ></stop>
        <stop
          className="stopColordbc4e9 svgShape"
          offset="0.764"
          stopColor="#e9cfc4"
        ></stop>
        <stop
          className="stopColorebdef3 svgShape"
          offset="1"
          stopColor="#f3e4de"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_21_)"
        d="M142.3 227.3l3.6 2c.3.2.7-.1.7-.4v-1.6c0-.5-.3-1-.7-1.2l-1.1-.6c-.5-.2-1-.2-1.4.1l-1.1.8c-.3.3-.3.8 0 .9z"
      ></path>
      <linearGradient
        id="SVGID_22_"
        x1="5106.538"
        x2="5109.129"
        y1="216.918"
        y2="220.746"
        gradientTransform="translate(-4979.429)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColor9f79c8 svgShape"
          offset="0"
          stopColor="#a0a0a0"
        ></stop>
        <stop
          className="stopColora580cb svgShape"
          offset="0.137"
          stopColor="#cb9680"
        ></stop>
        <stop
          className="stopColorb492d3 svgShape"
          offset="0.329"
          stopColor="#d3a592"
        ></stop>
        <stop
          className="stopColorcdb0e1 svgShape"
          offset="0.553"
          stopColor="#e1beb0"
        ></stop>
        <stop
          className="stopColord1b4e3 svgShape"
          offset="0.581"
          stopColor="#e3c2b4"
        ></stop>
        <stop
          className="stopColordbc4e9 svgShape"
          offset="0.764"
          stopColor="#e9cfc4"
        ></stop>
        <stop
          className="stopColorebdef3 svgShape"
          offset="1"
          stopColor="#f3e4de"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_22_)"
        d="M127.5 217.8v1.3c0 .2.1.3.3.4l1.6.8c.3.2.6-.1.5-.5l-.6-1.7c-.1-.2-.3-.4-.5-.6l-.5-.2c-.5-.1-.8.2-.8.5z"
      ></path>
      <linearGradient
        id="SVGID_23_"
        x1="5126.087"
        x2="5122.377"
        y1="226.679"
        y2="227.798"
        gradientTransform="translate(-4979.429)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColore8a838 svgShape"
          offset="0"
          stopColor="#e86238"
        ></stop>
        <stop
          className="stopColoredbc65 svgShape"
          offset="0.248"
          stopColor="#ed8665"
        ></stop>
        <stop
          className="stopColorfaedd7 svgShape"
          offset="0.808"
          stopColor="#ffe9e0"
        ></stop>
        <stop
          className="stopColorffffff svgShape"
          offset="1"
          stopColor="#fff"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_23_)"
        d="M142.3 227.1l3.6 2c.3.2.7-.1.7-.4v-1.6c0-.5-.3-1-.7-1.2l-1.1-.6c-.5-.2-1-.2-1.4.1l-1.1.8c-.3.2-.3.7 0 .9z"
      ></path>
      <linearGradient
        id="SVGID_24_"
        x1="5106.538"
        x2="5109.129"
        y1="216.712"
        y2="220.54"
        gradientTransform="translate(-4979.429)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColore8a838 svgShape"
          offset="0"
          stopColor="#e86238"
        ></stop>
        <stop
          className="stopColoredbc65 svgShape"
          offset="0.248"
          stopColor="#ed8665"
        ></stop>
        <stop
          className="stopColorfaedd7 svgShape"
          offset="0.808"
          stopColor="#ffe9e0"
        ></stop>
        <stop
          className="stopColorffffff svgShape"
          offset="1"
          stopColor="#fff"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_24_)"
        d="M127.5 217.6v1.3c0 .2.1.3.3.4l1.6.8c.3.2.6-.1.5-.5l-.6-1.7c-.1-.2-.3-.4-.5-.6l-.5-.2c-.5-.1-.8.1-.8.5z"
      ></path>
      <g className="color000000 svgShape">
        <path
          fill="#262626"
          d="M152.7 229.7c0 1.3.5 2.3 1.2 2.7l2.4 1.4c.8.4.3-.5 1.5-1.2 2.4-1.4 4.3-4.7 4.3-7.4 0-1.3 1.2-1.4.5-1.8l-2.5-1.4c-.8-.4-1.8-.3-3 .3-2.5 1.3-4.4 4.6-4.4 7.4z"
          className="color262626 svgShape"
        ></path>
        <path
          fill="#262626"
          d="M155.1 231.1c0 2.7 1.9 3.8 4.3 2.4s4.3-4.7 4.3-7.4-1.9-3.8-4.3-2.4c-2.3 1.3-4.3 4.7-4.3 7.4z"
          className="color262626 svgShape"
        ></path>
        <radialGradient
          id="SVGID_25_"
          cx="-4118.292"
          cy="228.451"
          r="4.631"
          gradientTransform="matrix(-1 0 0 1 -3959.751 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            className="stopColorffffff svgShape"
            offset="0"
            stopColor="#fff"
          ></stop>
          <stop offset="1"></stop>
        </radialGradient>
        <path
          fill="url(#SVGID_25_)"
          d="M159.2 223.6c1.1-.7 2.2-.7 2.9-.4-.7-.2-1.6-.1-2.6.5-2.4 1.4-4.3 4.7-4.3 7.4 0 1.4.5 2.4 1.4 2.8-1-.3-1.7-1.3-1.7-2.9 0-2.7 2-6.1 4.3-7.4z"
        ></path>
        <path
          fill="#a0a0a0"
          d="M159.5 225.6c-1.5.9-2.8 3-2.8 4.8 0 1.8 1.2 2.5 2.8 1.6 1.5-.9 2.8-3 2.8-4.8-.1-1.7-1.3-2.5-2.8-1.6z"
          className="colora0a0a0 svgShape"
        ></path>
        <path
          fill="#7b7b7b"
          d="M158.5 231.4c1.5-.9 2.8-3 2.8-4.8 0-.5-.1-1-.3-1.3.8.1 1.3.8 1.3 1.9 0 1.8-1.2 3.9-2.8 4.8-1.1.6-2 .5-2.4-.3.3.1.8 0 1.4-.3z"
          className="color7b7c7c svgShape"
        ></path>
        <linearGradient
          id="SVGID_26_"
          x1="5133.765"
          x2="5141.063"
          y1="226.632"
          y2="230.416"
          gradientTransform="translate(-4979.429)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            className="stopColor8770b8 svgShape"
            offset="0"
            stopColor="#949494"
          ></stop>
          <stop
            className="stopColorffffff svgShape"
            offset="1"
            stopColor="#fff"
          ></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_26_)"
          d="M157.5 230.2c0 1.1.8 1.5 1.7 1 .9-.5 1.7-1.9 1.7-2.9 0-1.1-.8-1.5-1.7-1-.9.5-1.7 1.8-1.7 2.9z"
        ></path>
        <path
          fill="#7b7b7b"
          d="M157.7 229.5c0 .9.6 1.3 1.4.8.8-.5 1.4-1.6 1.4-2.5 0-.9-.6-1.3-1.4-.8-.8.5-1.4 1.6-1.4 2.5z"
          className="color7b7c7c svgShape"
        ></path>
        <path
          fill="#c9c8c7"
          d="M158.1 229.8c0 .9.6 1.3 1.4.8.8-.5 1.4-1.6 1.4-2.5 0-.9-.6-1.3-1.4-.8-.8.5-1.4 1.6-1.4 2.5z"
          className="colorc9c8c7 svgShape"
        ></path>
      </g>
      <path
        fill="#3e3e3e"
        d="M163.6 211l-2.1 1.2c-.5.3-1-.1-1-.6v-6.2c0-.4.2-.7.5-.9l2.3-1.2c.3-.2.7.1.7.4v6.4c0 .4-.1.7-.4.9z"
        className="color2e324f svgShape"
      ></path>
      <linearGradient
        id="SVGID_27_"
        x1="-31.38"
        x2="-16.547"
        y1="217.938"
        y2="217.938"
        gradientTransform="translate(160.994)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop
          className="stopColorbec4e4 svgShape"
          offset="1"
          stopColor="#e4c9be"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_27_)"
        d="M142.4 224.7l-2.3 1.3c-.4.2-.9.2-1.3 0l-7.6-4c-.3-.2-.5-.4-.6-.8l-1-2.9c-.1-.3-.1-.6-.1-.9l.6-7.7 14.3 8.1-1.2 5.7c0 .5-.3 1-.8 1.2z"
      ></path>
      <linearGradient
        id="SVGID_28_"
        x1="-11.839"
        x2="-0.163"
        y1="225.558"
        y2="225.558"
        gradientTransform="translate(160.994)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop
          className="stopColorbec4e4 svgShape"
          offset="1"
          stopColor="#e4c9be"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_28_)"
        d="M152.2 233.7L152.2 228.2 155.8 222 160.8 219.1 157.8 217.4 152.7 220.3 149.2 226.5 149.2 232z"
      ></path>
      <linearGradient
        id="SVGID_29_"
        x1="-8.779"
        x2="3.433"
        y1="226.435"
        y2="226.435"
        gradientTransform="translate(160.994)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          className="stopColorffffff svgShape"
          offset="0"
          stopColor="#fff"
        ></stop>
        <stop
          className="stopColorbec4e4 svgShape"
          offset="1"
          stopColor="#e4c9be"
        ></stop>
      </linearGradient>
      <path
        fill="url(#SVGID_29_)"
        d="M153.1 233.3L153.1 228.4 156.4 222.7 160.9 220.1 163.6 221.7 163.6 225.5 164.4 225 164.4 221.2 160.9 219.1 155.8 222 152.2 228.2 152.2 233.7z"
      ></path>
      <g className="color000000 svgShape">
        <path
          fill="#262626"
          d="M126 220.7l19.2 11.4c.9.5 2.1.5 3 0l3.6-2v3.3l-3.6 2c-.9.5-2.1.5-3 0l-19.1-11v-3.7z"
          className="color262626 svgShape"
        ></path>
        <path
          fill="#545454"
          d="M148.2 231c-.9.5-2.1.5-3 0l-17.7-10.2v-1.3l-1.2.7c-.4.3-.4.9 0 1.2l18.8 10.8c.9.5 2.1.5 3 0l3.6-2-1-.6"
          className="color494949 svgShape"
        ></path>
        <radialGradient
          id="SVGID_30_"
          cx="5106.892"
          cy="220.013"
          r="0.387"
          gradientTransform="translate(-4979.429)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            className="stopColor9f19ab svgShape"
            offset="0"
            stopColor="#626262"
          ></stop>
          <stop
            className="stopColor9c19a8 svgShape"
            offset="0.008"
            stopColor="#606060"
          ></stop>
          <stop
            className="stopColor73127c svgShape"
            offset="0.127"
            stopColor="#474747"
          ></stop>
          <stop
            className="stopColor500c56 svgShape"
            offset="0.253"
            stopColor="#313131"
          ></stop>
          <stop
            className="stopColor330836 svgShape"
            offset="0.382"
            stopColor="#1f1f1f"
          ></stop>
          <stop
            className="stopColor1c041e svgShape"
            offset="0.516"
            stopColor="#111"
          ></stop>
          <stop
            className="stopColor0c020d svgShape"
            offset="0.657"
            stopColor="#070707"
          ></stop>
          <stop
            className="stopColor030003 svgShape"
            offset="0.811"
            stopColor="#010101"
          ></stop>
          <stop offset="1"></stop>
        </radialGradient>
        <path
          fill="url(#SVGID_30_)"
          d="M127.4 220.5L127.5 220.6 127.5 219.5 127.5 219.5z"
        ></path>
      </g>
      <g className="color000000 svgShape">
        <path
          d="M189.4 364.4c7.1 4.2 18.7 4.3 25.9.2l161.4-91.8c7.2-4.1 7.2-10.8 0-15l-74.2-43.7c-7.1-4.2-13.5-1.2-20.6 2.9l-161.4 91.8c-7.2 4.1-7.2 10.8 0 15l68.9 40.6z"
          className="color000000 svgShape"
          opacity="0.2"
        ></path>
        <path
          fill="#f6a686"
          d="M383.7 260.3l.1-10.9c0 2.7-1.8 5.4-5.4 7.4l-.1 10.9c3.6-2.1 5.4-4.7 5.4-7.4zm-266.8 39.8l-.1 10.9c0 2.7 1.7 5.5 5.3 7.6l.1-10.9c-3.6-2.1-5.3-4.9-5.3-7.6z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M217.1 348.6L217 359.5 378.4 267.7 378.5 256.7z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M122.2 307.7L122.1 318.7 191.1 359.3 191.2 348.3z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M191.2 348.3l-.1 10.9c7.1 4.2 18.7 4.3 25.9.2l.1-10.9c-7.2 4.1-18.8 4-25.9-.2z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#ffebeb"
          d="M191.2 348.3c7.1 4.2 18.7 4.3 25.9.2l161.4-91.8c7.2-4.1 7.2-10.8 0-15L304.3 198c-7.1-4.2-13.5-1.2-20.6 2.9l-161.4 91.8c-7.2 4.1-7.2 10.8 0 15l68.9 40.6z"
          className="colorebf3ff svgShape"
        ></path>
        <g className="color000000 svgShape">
          <path
            fill="#e5dcd8"
            d="M342.1 230.8c.6.3 1.5.3 2.1 0 .6-.3.6-.9 0-1.2l-17.7-10.4c-.6-.3-1.5-.3-2.1 0-.6.3-.6.9 0 1.2l17.7 10.4z"
            className="colord8e1e5 svgShape"
          ></path>
        </g>
        <g className="color000000 svgShape">
          <path
            fill="#303030"
            d="M162.7 316.5c-.8-1.5-.1-3.1 2-4.2 1.7-1 3.9-1.5 6.3-1.4 2.4 0 4.7.6 6.3 1.6.9.5 1.6 1.2 1.9 1.8.8 1.5.1 3.1-2 4.2-1.7 1-3.9 1.5-6.3 1.4-2.4 0-4.7-.6-6.3-1.6-.8-.6-1.5-1.2-1.9-1.8m-1 .5c.5.8 1.2 1.6 2.4 2.2 3.8 2.2 10 2.3 13.8.1 2.7-1.5 3.5-3.8 2.4-5.8-.5-.8-1.2-1.6-2.4-2.2-3.8-2.2-10-2.3-13.8-.1-2.7 1.6-3.5 3.8-2.4 5.8z"
            className="color2a2d37 svgShape"
          ></path>
        </g>
        <path
          fill="#f6a686"
          d="M143.4 286.5l83.3 48.5 143.7-81.3-81.8-48.4c.1 0-145.5 82.5-145.2 81.2z"
          className="color86aef6 svgShape"
        ></path>
        <g className="color000000 svgShape">
          <path
            fill="#494949"
            d="M340.6 280.4l11.2-6.3c.5-.3 1.1.6 1.1 1.1l-.1 4c0 .5-.3 1-.7 1.2l-11.2 7.1c-.4.2-1.2-.6-1.2-1.1l.1-4.7c0-.5.3-1 .8-1.3z"
            className="color404552 svgShape"
          ></path>
          <path
            fill="#ffebeb"
            d="M341.3 281l10.8-6.1c.5-.3 1 .1 1 .6v4.5c0 .5-.3 1-.7 1.2l-11.2 6.3c-.4.2-.9-.1-.9-.5l.1-4.7c.1-.5.4-1 .9-1.3z"
            className="colorebf3ff svgShape"
          ></path>
        </g>
        <g className="color000000 svgShape">
          <path
            fill="#494949"
            d="M355.1 272.3l11.2-6.3c.5-.3 1.1.6 1.1 1.1l-.1 4c0 .5-.3 1-.7 1.2l-11.2 7.1c-.4.2-1.2-.6-1.2-1.1l.1-4.7c.1-.5.3-1 .8-1.3z"
            className="color404552 svgShape"
          ></path>
          <path
            fill="#ffebeb"
            d="M355.8 272.9l10.8-6.1c.5-.3 1 .1 1 .6v4.5c0 .5-.3 1-.7 1.2l-11.2 6.3c-.4.2-.9-.1-.9-.5l.1-4.7c.2-.5.4-1 .9-1.3z"
            className="colorebf3ff svgShape"
          ></path>
        </g>
        <g className="color000000 svgShape">
          <path
            fill="#494949"
            d="M314.2 295.9l11.2-6.3c.5-.3 1.1.6 1.1 1.1l-.1 4c0 .5-.3 1-.7 1.2l-11.2 7.1c-.4.2-1.2-.6-1.2-1.1l.1-4.7c0-.6.3-1.1.8-1.3z"
            className="color404552 svgShape"
          ></path>
          <path
            fill="#ffebeb"
            d="M314.9 296.5l10.8-6.1c.5-.3 1 .1 1 .6v4.5c0 .5-.3 1-.7 1.2l-11.2 6.3c-.4.2-.9-.1-.9-.5l.1-4.7c.1-.6.4-1.1.9-1.3z"
            className="colorebf3ff svgShape"
          ></path>
        </g>
      </g>
      <g className="color000000 svgShape">
        <path
          fill="#fcc2aa"
          fillRule="evenodd"
          d="M103.7 317.5s1-.5.9-.7v-1c0-.2.1-.9.1-1s.6-.5.7-.5h1.2s.1-.5-.1-.5c-.3 0-1.3-.1-1.5-.1-.2 0-.8.5-.9.8-.1.3-1.1 2.4-1.1 2.4l.7.6z"
          className="colorfcc7aa svgShape"
          clipRule="evenodd"
        ></path>
        <path
          fill="#9a9a9a"
          fillRule="evenodd"
          d="M103.3 316s.8 1.2.8 1.7c-.1.4-4.5 4.3-5.6 4.5-1.1.2-1.9 0-2.3-1.2s7.1-5 7.1-5z"
          className="color566bde svgShape"
          clipRule="evenodd"
        ></path>
        <path
          fill="#9a9a9a"
          fillRule="evenodd"
          d="M96.1 321.1c2.7 2.5 4.2 4.6 6.1 5 .3.4 2.6-2.1 1.7-2.9-2.1-1.8-3.3-4.2-5-5.7-2.7-2.5-3.8-2.3-4.7-1.4-1 1-.8 2.5 1.9 5z"
          className="color566bde svgShape"
          clipRule="evenodd"
        ></path>
        <path
          fill="#2b2b2b"
          d="M107.4 310.7l-2.6 5.4c-.1.2 0 .4.2.5l3.1 1.8c.2.1.5 0 .6-.2l2.6-5.4c.1-.2 0-.4-.2-.5l-3.1-1.8c-.2-.1-.5 0-.6.2z"
          className="color2b2b2b svgShape"
        ></path>
        <linearGradient
          id="SVGID_31_"
          x1="4014.512"
          x2="4020.048"
          y1="314.537"
          y2="314.537"
          gradientTransform="translate(-3909.238)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            className="stopColor4c6ac0 svgShape"
            offset="0"
            stopColor="#868686"
          ></stop>
          <stop
            className="stopColor424c81 svgShape"
            offset="1"
            stopColor="#616161"
          ></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_31_)"
          d="M107.6 311.2l-2.3 4.9c-.1.2 0 .4.2.5l2.4 1.4c.2.1.4 0 .5-.2l2.3-4.9c.1-.2 0-.4-.2-.5l-2.4-1.4c-.1 0-.4 0-.5.2z"
        ></path>
        <ellipse
          cx="105"
          cy="382.2"
          fill="#222"
          className="color26231f svgShape"
          opacity="0.15"
          rx="13.6"
          ry="7.6"
        ></ellipse>
        <path
          fill="#fcc2aa"
          d="M95.3 350.8s2 10.9 1.7 12.6c-.3 1.7-.5 4.2 0 8.7.5 4.5.8 5.9.8 7.5s.1 3.5.5 3.9c.4.4 2.1.8 3.2-.1s3.5-.1 4.4-1.4c.8-1.4-.7-.7-2-.8-1.3-.1-2.5-.9-2.9-1.2 0 0 1.6-13.9.9-16.2-.6-2.3-.2-10.8-.2-10.8l-6.4-2.2z"
          className="colorfcc7aa svgShape"
        ></path>
        <path
          fill="#2b2b2b"
          d="M103.6 380.9s.9 0 .9.4c0 .3-.2.5-.9.6-.6.1-1.6.4-2.2.8-.6.3-2.5-1.3-3.6-1.2 0 0-.3 2.3.2 2.5.5.2 1.7.6 2.3.3.5-.3.7-.7 1.5-.7s3-.1 4.1-.7c1.1-.6 1.3-2 1.2-2.2 0-.2-3.5.2-3.5.2z"
          className="color2b2b2b svgShape"
        ></path>
        <path
          fill="#fcc2aa"
          d="M100.1 352.2s2 10.9 1.7 12.6c-.3 1.7-.5 4.2 0 8.7.5 4.5.8 5.9.8 7.5s.1 3.5.5 3.9c.4.4 2.1.8 3.2-.1s3.5-.1 4.4-1.4c.8-1.4-.7-.7-2-.8-1.3-.1-2.5-.9-2.9-1.2 0 0 1.6-13.9.9-16.2-.6-2.3-.2-10.8-.2-10.8l-6.4-2.2z"
          className="colorfcc7aa svgShape"
        ></path>
        <path
          fill="#b3b3b3"
          d="M105.3 320.3c1.3 1.7.1 7.3-.7 9.3-1.7 4.4.4 5.8.6 9.2.2 2.3.1 7 .1 7-2.1.7-3.8-.4-4.6-.5-2.8-.3-6.3-2.2-6.5-3.5-.4-3.3 1.6-4.7 1.7-7.1.2-4.4-.2-6.8-.4-7.6-.8-4.2-1.3-8 .6-10.4.4-.6 1.3-.9 2-.8 2.5.1 5.8 2.6 7.2 4.4z"
          className="color687cff svgShape"
        ></path>
        <path
          fill="#fcc2aa"
          d="M103.8 308.3c0 .6.4 1.4.6 2 .1.4-.2 2-.1 2.6.2.7 0 1.4-.2 2.1-.2.5-.3 1.1-.7 1.5-.5.5-2.6-.2-3.8-.7-1.2-.4-.9-2-1.3-3-.7-1.8-.2-4.3 1.8-5.1.9-.3 2.3-.5 3.2-.2.3.1-.4.4-.7.8h1.2z"
          className="colorfcc7aa svgShape"
        ></path>
        <path
          fill="#fcc2aa"
          d="M102 315.3s-.5 1.1 0 2.3c.1.3-.1 1.1-1 1.3-1.3.3-3-.1-2.8-1.3.1-.6.6-1.4-.3-3.7-.2-.7 4.1 1.4 4.1 1.4z"
          className="colorfcc7aa svgShape"
        ></path>
        <path
          fill="#2b2b2b"
          d="M97.8 306.2c2.6-.9 4.2-.8 5.4.6.7.8.7 1.8.7 1.8v.2c.2.6.2 1.3-.2 2.1-1.1 2.7-1.1 2.2-1.2 3 0 .3-.3.1-.5-.4-1.6 3.6-4 1.7-4.1 1.5-.9-2.2-1.5-1.7-1.9-3-.6-1.9-.7-4.7 1.8-5.8z"
          className="color2b2b2b svgShape"
        ></path>
        <path
          fill="#fcc2aa"
          d="M102.8 313.7c0-.3 0-.7-.3-.9-.3-.3-.5-.1-.7.2-.1.3 0 .5 0 .8 0 .2.2 1.8 1 .9v-1z"
          className="colorfcc7aa svgShape"
        ></path>
        <path
          fill="#2b2b2b"
          d="M98.2 309.3s2.8 1.6 1.3 3.4c-1.5 1.7-2.6 2.8-2.7 3.7 0 .9 1.4 3.2.3 4-1.1.8-3 2.2-2.1 3.3.4.5-1.9-1.1-1.3-2.4.6-1.1 1.5-.8-.2-3-1.8-2.3-1.8-3.9-.3-5.2 1.5-1.5 1.4-5.4 5-3.8zm-2.4 26.2s2.6 3 9.3.7c0 0 1.8 3.2 2.4 5.1.6 1.9.6 5.1.2 5.5-.4.4-3.8 3.3-8.2 1.1-4.4-2.3-6.1-4.9-6.1-6.5 0-1.7 2.4-5.9 2.4-5.9z"
          className="color2b2b2b svgShape"
        ></path>
        <path
          fill="#2b2b2b"
          d="M93.9 340.2s1.6 14.3 1.4 17c-.2 2.7-.3 4.6-.3 4.6s7 2.6 9.6 2.6c2.6 0 3.3-.5 3.3-.5s.5-14.4-.2-18.7c-.5-4.2-13.8-5-13.8-5zm14.4 42.2s.9 0 .9.4c0 .3-.2.5-.9.6-.6.1-1.6.4-2.2.8-.6.3-2.5-1.3-3.6-1.2 0 0-.3 2.3.2 2.5.5.2 1.7.6 2.3.3.5-.3.7-.7 1.5-.7s3-.1 4.1-.7c1.1-.6 1.3-2 1.2-2.2s-3.5.2-3.5.2z"
          className="color2b2b2b svgShape"
        ></path>
        <path
          fill="#fee"
          d="M97.9 317.6c0-.4.4-.6.7-.5.6.3 1.5.6 2.1.5.3-.1.6-.2.8-.2.3-.1.7 0 .8.3.1.2.2.5.2.6 0 .4-2.5 1.4-3.7.6-1.1-.9-.9-1-.9-1.3z"
          className="coloreef9ff svgShape"
        ></path>
        <path
          fill="#fcc2aa"
          fillRule="evenodd"
          d="M110.2 320.6s.1-2.2.2-2.5c.1-.3.3-.9.3-1.1s.1-.2 0-.4c-.1-.1-.5-.9-.6-1-.1-.1-.5-1-.7-.9-.2.1-.2.4-.1.6.1.2.3.6.3.8s-.5.8-.6.9c-.1.1-.1.2-.2.3-.1.1-.4 1.1-.4 1.3v1.1l1.8.9z"
          className="colorfcc7aa svgShape"
          clipRule="evenodd"
        ></path>
        <path
          fill="#9a9a9a"
          fillRule="evenodd"
          d="M103.4 324.6c1.8 3.3 2.6 5.6 4.2 6.7.1.5 3.1-1.2 2.5-2.2-1.4-2.4-1.9-5-3-7-1.8-3.3-2.9-3.4-4-2.8-1.2.6-1.5 2 .3 5.3z"
          className="color566bde svgShape"
          clipRule="evenodd"
        ></path>
        <path
          fill="#9a9a9a"
          fillRule="evenodd"
          d="M110.4 319.7s1.1 9.6-.7 10.4c-1.8.9-3.1.1-3.1.1s.6-2.6.9-3.6c.3-1 .9-7.6 1-7.8.2 0 1.2-.9 1.9.9z"
          className="color566bde svgShape"
          clipRule="evenodd"
        ></path>
      </g>
      <g className="color000000 svgShape">
        <linearGradient
          id="SVGID_32_"
          x1="3848.774"
          x2="3870.277"
          y1="179.307"
          y2="179.307"
          gradientTransform="matrix(-1 0 0 1 4138.352 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            className="stopColore4b45d svgShape"
            offset="0"
            stopColor="#e47d5d"
          ></stop>
          <stop
            className="stopColore49c5d svgShape"
            offset="0.592"
            stopColor="#e47d5d"
          ></stop>
          <stop
            className="stopColore4905d svgShape"
            offset="1"
            stopColor="#e47d5d"
          ></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_32_)"
          d="M286.3 181l-.4-8.1c0-3.6-1.2-5.9-4.3-4.1-6.5 3.9-10.5 10.9-10.5 18.4v6.9l-3-1.9v-8.1c0-7 3.8-13.4 10-16.8l4-2.2c4.4-2.4 7.2.9 7.1 5.9l.4 12.1-3.3-2.1z"
        ></path>
        <path
          fill="#545454"
          d="M249.9 240.3L268.5 251 308.5 228.8 288.8 218.6z"
          className="color494949 svgShape"
          opacity="0.22"
        ></path>
        <path
          fill="#fb9a73"
          d="M303.3 229.3L269.1 249.2 264.3 198.1 299 178z"
          className="colorfbba73 svgShape"
        ></path>
        <path
          fill="#e47d5d"
          d="M269.1 249.2L262.4 240.3 252 239.7 260.9 195.5 264.3 198.1z"
          className="colore4905d svgShape"
        ></path>
        <path
          fill="#e47d5d"
          d="M299 178L295.5 175.4 260.9 195.5 264.3 198.1z"
          className="colore4905d svgShape"
        ></path>
        <path
          fill="#ba674d"
          d="M297.7 177.9L295.4 176.2 262.4 195.4 264.7 197.1z"
          className="colorba744d svgShape"
        ></path>
        <linearGradient
          id="SVGID_33_"
          x1="3843.187"
          x2="3864.69"
          y1="182.777"
          y2="182.777"
          gradientTransform="matrix(-1 0 0 1 4138.352 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            className="stopColore4b45d svgShape"
            offset="0"
            stopColor="#e47d5d"
          ></stop>
          <stop
            className="stopColore49c5d svgShape"
            offset="0.592"
            stopColor="#e47d5d"
          ></stop>
          <stop
            className="stopColore4905d svgShape"
            offset="1"
            stopColor="#e47d5d"
          ></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_33_)"
          d="M291.9 185.4l-.4-8.1c0-3.6-1.2-5.9-4.3-4.1-6.5 3.9-10.5 10.9-10.5 18.4v3.4l-3 1.6v-8.1c0-7 3.8-13.4 10-16.8l4-2.2c4.4-2.4 7.2.9 7.1 5.9l.4 8.1-3.3 1.9z"
        ></path>
      </g>
      <g className="color000000 svgShape">
        <path
          fill="#f48562"
          d="M307.5 194.3l-.4-8.1c0-3.6-1.2-5.9-4.3-4.1-6.5 3.9-10.5 10.9-10.5 18.4v6.9l-3-1.9v-8.1c0-7 3.8-13.4 10-16.8l4-2.2c4.4-2.4 7.2.9 7.1 5.9l.4 12.1-3.3-2.1z"
          className="colorf46862 svgShape"
        ></path>
        <path
          fill="#545454"
          d="M271.1 253.6L289.7 264.2 329.7 242.1 310 231.9z"
          className="color494949 svgShape"
          opacity="0.22"
        ></path>
        <path
          fill="#ff5722"
          d="M324.5 242.5L290.3 262.4 285.5 211.4 320.2 191.2z"
          className="colorfe4a39 svgShape"
        ></path>
        <path
          fill="#a54121"
          d="M290.3 262.4L283.6 253.6 273.2 252.9 282.1 208.8 285.5 211.4z"
          className="colora52121 svgShape"
        ></path>
        <path
          fill="#f48562"
          d="M320.2 191.2L316.7 188.6 282.1 208.8 285.5 211.4z"
          className="colorf46862 svgShape"
        ></path>
        <path
          fill="#a54121"
          d="M318.9 191.1L316.6 189.5 283.6 208.6 285.9 210.3z"
          className="colora52121 svgShape"
        ></path>
        <path
          fill="#f48562"
          d="M313.1 198.7l-.4-8.1c0-3.6-1.2-5.9-4.3-4.1-6.5 3.9-10.5 10.9-10.5 18.4v3.4l-3 1.6v-8.1c0-7 3.8-13.4 10-16.8l4-2.2c4.4-2.4 7.2.9 7.1 5.9l.4 8.1-3.3 1.9z"
          className="colorf46862 svgShape"
        ></path>
      </g>
      <g className="color000000 svgShape">
        <path
          fill="#f96d41"
          d="M333.7 208.2l-.4-8.1c0-3.6-1.2-5.9-4.3-4.1-6.5 3.9-10.5 10.9-10.5 18.4v6.9l-3-1.9v-8.1c0-7 3.8-13.4 10-16.8l4-2.2c4.4-2.4 7.2.9 7.1 5.9l.4 12.1-3.3-2.1z"
          className="colorf9d641 svgShape"
        ></path>
        <path
          fill="#545454"
          d="M297.3 267.5L315.9 278.2 355.9 256 336.2 245.8z"
          className="color494949 svgShape"
          opacity="0.22"
        ></path>
        <path
          fill="#fb531e"
          d="M350.7 256.5L316.5 276.4 311.7 225.3 346.4 205.1z"
          className="colorfbbb1e svgShape"
        ></path>
        <path
          fill="#e04e20"
          d="M316.5 276.4L309.8 267.5 299.4 266.8 308.3 222.7 311.7 225.3z"
          className="colore09220 svgShape"
        ></path>
        <path
          fill="#f96d41"
          d="M346.4 205.1L342.9 202.6 308.3 222.7 311.7 225.3z"
          className="colorf9d641 svgShape"
        ></path>
        <path
          fill="#e04e20"
          d="M345.1 205.1L342.8 203.4 309.8 222.6 312.1 224.3z"
          className="colore09220 svgShape"
        ></path>
        <path
          fill="#f96d41"
          d="M339.3 212.6l-.4-8.1c0-3.6-1.2-5.9-4.3-4.1-6.5 3.9-10.5 10.9-10.5 18.4v3.4l-3 1.6v-8.1c0-7 3.8-13.4 10-16.8l4-2.2c4.4-2.4 7.2.9 7.1 5.9l.4 8.1-3.3 1.9z"
          className="colorf9d641 svgShape"
        ></path>
      </g>
      <g className="color000000 svgShape">
        <path
          fill="#535353"
          d="M249.1 277.9L215.3 258.2 215.4 286.2 249.2 305.2z"
          className="color8b9116 svgShape"
        ></path>
        <path
          fill="#ed5c2e"
          d="M290.8 253L257.1 233.3 215.3 258.3 249.1 277.9z"
          className="coloreddf2e svgShape"
        ></path>
        <path
          fill="#b23d18"
          d="M249.1 277.9L249.2 305.2 290.8 280.9 290.8 253z"
          className="colorb2b218 svgShape"
        ></path>
        <path
          fill="#535353"
          d="M231.8 257.9H232.60000000000002V296.9H231.8z"
          className="color8b9116 svgShape"
          opacity="0.3"
          transform="rotate(-59.743 232.197 277.36)"
        ></path>
        <path
          fill="#535353"
          d="M245.6 274.3H294.3V275.1H245.6z"
          className="color8b9116 svgShape"
          transform="rotate(-30.829 269.928 274.712)"
        ></path>
        <path
          fill="#ea5c2f"
          d="M278.8 259.9L278.9 287.9 273.1 291.2 273 263.3z"
          className="colorea382f svgShape"
        ></path>
        <path
          fill="#ff6c3e"
          d="M245 240.3L278.8 259.9 278.8 259.9 278.8 259.9 273 263.3 239.2 243.6z"
          className="colorff503e svgShape"
        ></path>
        <path
          fill="#f9683a"
          d="M268.5 266L262.7 269.3 261.3 266.8 252.4 251.2 258.2 247.9 267.4 264z"
          className="colorf9423a svgShape"
        ></path>
        <path
          fill="#ff6c3e"
          d="M254.3 249L260 252.4 254.7 263.5 247.5 262.7z"
          className="colorff503e svgShape"
        ></path>
        <path
          fill="#ff6c3e"
          d="M269 240.3L274.8 243.7 233.1 268.7 227.3 265.3 227.3 265.3z"
          className="colorff503e svgShape"
        ></path>
        <path
          fill="#a54121"
          d="M227.3 265.3L227.3 265.3 233.1 268.7 233 296.2 227.2 293z"
          className="colora52121 svgShape"
        ></path>
        <g className="color000000 svgShape">
          <path
            fill="#ff6c3e"
            d="M269.9 233.5c-.7-.2-1.5-.2-2.5 0-.5.1-1 .3-1.5.6 0 0-.1 0-.1.1-5.1 2.9-4.9 9.1-4.9 13.4 0 1.9-.1 3.4-.8 4.1 0 0-.2.1-.3.3l-5.6-4.2c.9-.9.8-3.3.9-6.2.1-4.1.6-9 5-11.4.1 0 .1-.1.2-.1.7-.3 1.3-.5 1.8-.6 1.4-.2 2.5.4 3.5 1.1.9.7 3.4 2.3 4.3 2.9z"
            className="colorff503e svgShape"
          ></path>
          <path
            fill="#ce5934"
            d="M260 251.8s-.1.2-.5.5l-1.1.4-4.4-4.8c.9-.9.8-3.3.9-6.2v.4c0 5.1 5.8 7.2 5.8 7.2s0 1.9-.7 2.5z"
            className="colorce3434 svgShape"
          ></path>
          <path
            fill="#ce5934"
            d="M266.4 248.1c-9.4 6.2-6.3 3.8-6.3 3.8.9-.9.8-3.4.9-6.3.1-.1.2-.2.4-.3 3.9-2.6 5.8-6.1 6.1-9.1.1-.9.1-1.8-.1-2.5 2.3-.7 3.9.1 4.7 1.9 1.3 3-.1 8.7-5.7 12.5z"
            className="colorce3434 svgShape"
          ></path>
          <path
            fill="#a54121"
            d="M267.4 236.1c.1-.9.1-1.8-.1-2.5 2.3-.7 3.9.1 4.7 1.9-1.5.2-3.2.4-4.6.6z"
            className="colora52121 svgShape"
          ></path>
        </g>
        <path
          fill="#ce5934"
          d="M260.3 251.7c-2.6 0-4.5-11.4-11.9-11.2 2.9 3.3 4.3 8.3 6 8.3 0 0 2.1-.2-8.3 3.8-1.7.6-3.2.9-4.5.9 1.7 2.6 5.2 3.9 10.3 1.9 10.6-3.9 8.4-3.7 8.4-3.7z"
          className="colorce3434 svgShape"
        ></path>
        <path
          fill="#ff6c3e"
          d="M251.2 241c-.8-.4-1.7-.6-2.7-.5-.6 0-1.3.1-2 .3-.1 0-.3.1-.4.1-2 .6-3.5 1.9-4.5 3.5-.9 1.6-1.3 3.4-1.2 5.1.1 2.1 1 4.2 2.7 5.5-1.5-.8-4.1-2.3-4.6-2.8-1.5-1.4-2.3-3.5-2.3-5.6 0-1.4.3-2.9 1-4.2.9-1.8 2.5-3.3 4.7-3.9.1 0 .2-.1.3-.1.3-.1.5-.1.8-.2.3 0 .5-.1.8-.1.9-.1 1.8 0 2.5.3.9.2 3.5 1.8 4.9 2.6z"
          className="colorff503e svgShape"
        ></path>
        <path
          fill="#a54121"
          d="M260.3 251.7c-2.6 0-4.5-11.4-11.9-11.2 2.9 3.3 4.3 8.3 6 8.3 0 0 1.4-.2-3.7 1.9l9.1 1.4c1.1-.5.5-.4.5-.4z"
          className="colora52121 svgShape"
        ></path>
        <path
          fill="#ea5c2f"
          d="M268.5 272.3L268.5 266 262.7 269.3 262.7 275.6z"
          className="colorea382f svgShape"
        ></path>
      </g>
      <g className="color000000 svgShape">
        <path
          fill="#f6a686"
          d="M316.9 156.4L318.7 155.3 320.1 151.7 318.2 152.8z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M311 128.3l-1.9 1.1c.3-.2.7-.1 1.1.1l1.9-1.1c-.4-.2-.8-.2-1.1-.1zm24 31c-.2 0-3.1.7-6.6 1.6l-1.9 1.1c3.5-.9 6.4-1.6 6.6-1.6.6-.3 1.4-.7 1.9-1.1z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#ffebeb"
          d="M333.1 160.4l-14.9-7.7-1.4 3.7-3.4 9.1c.4-.1 7.3-2 13.1-3.5 3.5-.9 6.4-1.6 6.6-1.6z"
          className="colorebf3ff svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M333.1 160.4L335 159.3 320.1 151.7 318.2 152.8z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M377.2 190.8l1.9-1.1c.3-.2.4-.5.4-1l-1.9 1.1c.1.5-.1.8-.4 1z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M377.7 189.8L379.6 188.7 379.6 169.2 377.7 170.3z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M376.2 167.6L378.1 166.5 312.1 128.5 310.2 129.6z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#ffebeb"
          d="M310.2 129.6c-.8-.5-1.5-.1-1.5.9l-.1 19.5c0 1 .7 2.2 1.5 2.6l6.7 3.9 1.4-3.7 14.9 7.7c-.2 0-3.1.7-6.6 1.6l49.6 28.6c.8.5 1.5.1 1.5-.9l.1-19.5c0-1-.7-2.2-1.5-2.6l-66-38.1z"
          className="colorebf3ff svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M377.7 170.3l1.9-1.1c0-1-.7-2.2-1.5-2.6l-1.9 1.1c.8.4 1.5 1.6 1.5 2.6z"
          className="color86aef6 svgShape"
        ></path>
      </g>
      <g className="color000000 svgShape">
        <path
          fill="#9a9a9a"
          d="M317.1 138.7c.9.5 1.7 1.1 2.6 1.8l-.3 1.9c-.8-.6-1.7-1.2-2.3-1.6-1-.6-1.3-.2-1.3.7v3.6c0 .9.3 1.6 1.3 2.2.7.4 1.6.8 2.3 1.1l.3 2.2c-.9-.3-1.7-.7-2.6-1.2-2.3-1.3-3.3-3.2-3.3-5.1V140c0-1.9 1-2.6 3.3-1.3zm8.8 5.2l2.7 12-2.1-1.2-.5-2.3-3-1.8-.5 1.8-2.1-1.2 2.8-8.8 2.7 1.5zm-2.5 5l2.1 1.2-1-5-1.1 3.8m9.3-1.2c.8.5 1.7 1.1 2.7 1.9l-.2 1.9c-1-.8-1.7-1.3-2.5-1.8-.9-.5-1.1-.5-1.1.3 0 1 0 1 1.5 2.3 2.4 2.1 2.6 2.7 2.6 4.8 0 2.1-.6 2.7-3.3 1.2-1-.6-2.1-1.5-2.9-2.4l.3-1.6c.8.8 1.7 1.5 2.6 2 1 .6 1.3.5 1.3-.4s0-1.1-1.5-2.3c-2.2-1.8-2.5-2.5-2.5-4.8-.2-2.1.5-2.6 3-1.1z"
          className="color5dd774 svgShape"
        ></path>
        <path
          fill="#9a9a9a"
          d="M344.1 154.4L344 164.8 342 163.7 342 159.5 339.1 157.8 339.1 162 337 160.8 337.1 150.4 339.1 151.5 339.1 155.7 342 157.4 342 153.2z"
          className="color5dd774 svgShape"
        ></path>
        <path
          fill="#9a9a9a"
          d="M351 163.4c.7.6 1.3 1.7 1.3 2.7v1c0 1.5-.8 2-2.6 1l-3.9-2.2v-10.4l3.6 2.1c1.9 1.1 2.6 2.4 2.6 3.8v1.1c0 .6-.3 1-1 .9zm-.7 2.2v-.6c0-.6-.3-1.1-.9-1.4l-1.6-.9v2.4l1.3.7c.7.4 1.2.6 1.2-.2m-2.4-7.1v2.4l1.3.7c.5.3.8.2.8-.3v-.9c0-.5-.2-.9-.8-1.2l-1.3-.7m10.5 4.2l2.7 12-2.1-1.2-.5-2.3-3-1.8-.5 1.8-2.1-1.2 2.8-8.8 2.7 1.5zm-2.5 5l2.1 1.2-1-5-1.1 3.8m9.4-1.2c.9.5 1.7 1.1 2.6 1.8l-.3 1.9c-.8-.6-1.7-1.2-2.3-1.6-1-.6-1.3-.2-1.3.7v3.6c0 .9.3 1.6 1.3 2.2.7.4 1.6.8 2.3 1.1l.3 2.2c-.9-.3-1.7-.7-2.6-1.2-2.3-1.3-3.3-3.2-3.3-5.1v-4.3c-.1-1.9 1-2.6 3.3-1.3z"
          className="color5dd774 svgShape"
        ></path>
        <path
          fill="#9a9a9a"
          d="M373.7 176.7L376.5 183.6 374.2 182.2 371.5 175.4 374 171.7 376.3 173z"
          className="color5dd774 svgShape"
        ></path>
        <path
          fill="#9a9a9a"
          d="M369.2 179.4L369.2 169 371.3 170.1 371.3 180.6z"
          className="color5dd774 svgShape"
        ></path>
      </g>
      <g className="color000000 svgShape">
        <path
          fill="#f6a686"
          d="M268.1 161.8L266.1 160.7 264.7 156.9 266.7 158z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M274.1 132.7l2 1.1c-.3-.2-.7-.1-1.1.1l-2-1.1c.4-.2.8-.2 1.1-.1zm-24.8 32.1c.2 0 3.2.7 6.8 1.6l2 1.1c-3.6-.9-6.6-1.7-6.8-1.6-.7-.3-1.4-.8-2-1.1z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#ffebeb"
          d="M251.2 165.9l15.4-7.9 1.4 3.8 3.5 9.4c-.4-.1-7.6-2.1-13.5-3.6-3.5-1-6.5-1.7-6.8-1.7z"
          className="colorebf3ff svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M251.2 165.9L249.3 164.8 264.7 156.9 266.7 158z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M241.5 176.5l-2-1.1c-.3-.2-.5-.5-.5-1l2 1.1c0 .5.2.9.5 1z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M241 175.5L239 174.4 239 154.2 240.9 155.3z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M242.5 152.6L240.6 151.5 273 132.8 275 134z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#ffebeb"
          d="M275 134c.9-.5 1.6-.1 1.6.9l.1 20.2c0 1-.7 2.2-1.6 2.7l-6.9 4-1.4-3.8-15.4 7.9c.2 0 3.2.7 6.8 1.6l-15.5 8.8c-.9.5-1.6.1-1.6-.9l-.1-20.2c0-1 .7-2.2 1.6-2.7L275 134z"
          className="colorebf3ff svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M240.9 155.3l-2-1.1c0-1 .7-2.2 1.6-2.7l2 1.1c-.9.5-1.6 1.7-1.6 2.7z"
          className="color86aef6 svgShape"
        ></path>
        <g className="color000000 svgShape">
          <path
            fill="#f96232"
            d="M258.9 153.2c0 1.9-.7 3.7-3.1 5.1-2.5 1.4-3.2.4-3.2-1.5v-1.7c0-1.9.7-3.7 3.2-5.1s3.2-.4 3.2 1.5l-.1 1.7zm-2.2 1.5v-2c0-.7-.2-.8-1-.4-.7.4-1 .8-1 1.5v2c0 .7.3.8 1 .4.7-.5 1-.9 1-1.5"
            className="colorf9dc32 svgShape"
          ></path>
          <path
            fill="#f96232"
            d="M262.1 146.4L263.9 146.1 258.6 164 256.9 164.2z"
            className="colorf9dc32 svgShape"
          ></path>
          <path
            fill="#f96232"
            d="M265.1 151.9c2.5-1.4 3.2-.4 3.2 1.5v1.7c0 1.9-.7 3.7-3.2 5.1-2.5 1.4-3.2.4-3.2-1.5V157c0-1.8.7-3.6 3.2-5.1zm1 4.6v-2c0-.7-.2-.8-1-.4-.7.4-1 .8-1 1.5v2c0 .7.3.8 1 .4s1-.8 1-1.5"
            className="colorf9dc32 svgShape"
          ></path>
        </g>
      </g>
      <g className="color000000 svgShape">
        <path
          fill="#f6a686"
          d="M78.5 300.3L77.4 299.6 76.6 297.4 77.7 298.1z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M82.1 283.3l1.1.7c-.2-.1-.4-.1-.7.1l-1.1-.7c.3-.2.5-.2.7-.1zM67.5 302c.1 0 1.9.4 4 1l1.1.7c-2.1-.6-3.9-1-4-1-.3-.2-.7-.5-1.1-.7z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#ffebeb"
          d="M68.7 302.7l9-4.6.8 2.2 2 5.5c-.2-.1-4.4-1.2-7.9-2.1-2-.6-3.8-1-3.9-1z"
          className="colorebf3ff svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M68.7 302.7L67.5 302 76.6 297.4 77.7 298.1z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M63 308.9l-1.1-.7c-.2-.1-.3-.3-.3-.6l1.1.7c0 .3.1.5.3.6z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M62.7 308.3L61.5 307.7 61.5 295.9 62.7 296.5z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M63.6 294.9L62.4 294.2 81.4 283.3 82.6 284z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#ffebeb"
          d="M82.6 284c.5-.3.9-.1.9.5v11.8c0 .6-.4 1.3-.9 1.6l-4.1 2.3-.8-2.2-9 4.6c.1 0 1.9.4 4 1l-9.1 5.2c-.5.3-.9.1-.9-.5v-11.8c0-.6.4-1.3.9-1.6l19-10.9z"
          className="colorebf3ff svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M62.7 296.5l-1.1-.7c0-.6.4-1.3.9-1.6l1.1.7c-.5.3-.9 1-.9 1.6z"
          className="color86aef6 svgShape"
        ></path>
      </g>
      <g className="color000000 svgShape">
        <path
          fill="#f6a686"
          d="M109.4 292.6L110.6 291.9 111.4 289.7 110.2 290.3z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M105.9 275.5l-1.1.7c.2-.1.4-.1.7.1l1.1-.7c-.3-.2-.6-.2-.7-.1zm14.5 18.8c-.1 0-1.9.4-4 1l-1.1.7c2.1-.6 3.9-1 4-1 .3-.2.8-.5 1.1-.7z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#ffebeb"
          d="M119.3 295l-9-4.6-.8 2.2-2 5.5c.2-.1 4.4-1.2 7.9-2.1 2-.6 3.7-1.1 3.9-1z"
          className="colorebf3ff svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M119.3 295L120.4 294.3 111.4 289.7 110.2 290.3z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M125 301.2l1.1-.7c.2-.1.3-.3.3-.6l-1.1.7c0 .3-.1.5-.3.6z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M125.3 300.6L126.4 299.9 126.4 288.1 125.3 288.8z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M124.4 287.2L125.5 286.5 106.5 275.6 105.4 276.3z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#ffebeb"
          d="M105.4 276.3c-.5-.3-.9-.1-.9.5v11.8c0 .6.4 1.3.9 1.6l4.1 2.3.8-2.2 9 4.6c-.1 0-1.9.4-4 1l9.1 5.2c.5.3.9.1.9-.5v-11.8c0-.6-.4-1.3-.9-1.6l-19-10.9z"
          className="colorebf3ff svgShape"
        ></path>
        <path
          fill="#f6a686"
          d="M125.3 288.8l1.1-.7c0-.6-.4-1.3-.9-1.6l-1.1.7c.5.3.9 1 .9 1.6z"
          className="color86aef6 svgShape"
        ></path>
      </g>
      <g className="color000000 svgShape">
        <path
          fill="#9a9a9a"
          d="M225.6 303.2c4.3 2.5 4.3 6.5.1 8.9-4.3 2.5-11.2 2.5-15.5 0l-29.3-16.9c-4.3-2.5-4.3-6.5 0-8.9 4.3-2.5 11.2-2.5 15.5 0l29.2 16.9z"
          className="color5dd774 svgShape"
        ></path>
      </g>
      <g className="color000000 svgShape">
        <path
          fill="#fffcfc"
          d="M196.8 295.3c.5.5.5 1.2-.3 1.6l-.8.5c-1.2.7-2.7.7-4.4-.2l-3.6-2.1 8.3-4.8 3.3 1.9c1.8 1 1.7 1.8.6 2.4l-.9.5c-.5.3-1.2.4-2.2.2zm-2.8.8l.5-.3c.5-.3.4-.6-.1-.9l-1.4-.8-1.9 1.1 1.2.7c.6.4 1.1.6 1.7.2m2.4-3.9l-1.9 1.1 1.2.7c.5.3 1 .3 1.3.1l.7-.4c.4-.2.4-.5-.2-.8l-1.1-.7m13.1 5.8l-5.9 3.4c-1.3.7-3.3 1-5.7-.3-2.4-1.4-1.9-2.6-.6-3.3l5.9-3.4 1.9 1.1-5.7 3.3c-.7.4-.7.8.2 1.4.9.5 1.7.5 2.3.1l5.7-3.3 1.9 1z"
          className="colorffffff svgShape"
        ></path>
        <path
          fill="#fffcfc"
          d="M217.4 302.5L208.8 304.5 206.4 305.9 204.5 304.8 206.9 303.4 210.3 298.4 212.2 299.6 209.9 302.7 215.4 301.4z"
          className="colorffffff svgShape"
        ></path>
      </g>
      <g className="color000000 svgShape">
        <path
          d="M303.4 345.3c6.4 3.7 6.5 9.7.1 13.5-6.4 3.7-16.8 3.7-23.2 0-6.4-3.7-6.5-9.7-.1-13.5 6.4-3.8 16.8-3.8 23.2 0z"
          className="color000000 svgShape"
          opacity="0.2"
        ></path>
        <path
          fill="#f6a686"
          d="M305.5 344.9v4.9c0 2.4-1.6 4.8-4.8 6.7-6.4 3.7-16.8 3.7-23.2 0-3.2-1.9-4.9-4.3-4.9-6.8v-4.9c0 2.5 1.6 4.9 4.9 6.8 6.4 3.7 16.8 3.7 23.2 0 3.2-1.9 4.8-4.3 4.8-6.7z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#ffebeb"
          d="M300.6 338.1c6.4 3.7 6.5 9.7.1 13.5-6.4 3.7-16.8 3.7-23.2 0-6.4-3.7-6.5-9.7-.1-13.5 6.4-3.7 16.8-3.7 23.2 0z"
          className="colorebf3ff svgShape"
        ></path>
      </g>
      <g className="color000000 svgShape">
        <path
          d="M339.9 326.5c6.4 3.7 6.5 9.7.1 13.5-6.4 3.7-16.8 3.7-23.2 0-6.4-3.7-6.5-9.7-.1-13.5 6.3-3.7 16.7-3.7 23.2 0z"
          className="color000000 svgShape"
          opacity="0.2"
        ></path>
        <path
          fill="#f6a686"
          d="M341.9 326.1v4.9c0 2.4-1.6 4.8-4.8 6.7-6.4 3.7-16.8 3.7-23.2 0-3.2-1.9-4.9-4.3-4.9-6.8V326c0 2.5 1.6 4.9 4.9 6.8 6.4 3.7 16.8 3.7 23.2 0 3.2-1.9 4.8-4.3 4.8-6.7z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#ffebeb"
          d="M337.1 319.3c6.4 3.7 6.5 9.7.1 13.5-6.4 3.7-16.8 3.7-23.2 0-6.4-3.7-6.5-9.7-.1-13.5 6.3-3.7 16.8-3.7 23.2 0z"
          className="colorebf3ff svgShape"
        ></path>
      </g>
      <g className="color000000 svgShape">
        <path
          d="M374.7 307.1c6.4 3.7 6.5 9.7.1 13.5-6.4 3.7-16.8 3.7-23.2 0-6.4-3.7-6.5-9.7-.1-13.5 6.4-3.7 16.8-3.7 23.2 0z"
          className="color000000 svgShape"
          opacity="0.2"
        ></path>
        <path
          fill="#f6a686"
          d="M376.8 306.7v4.9c0 2.4-1.6 4.8-4.8 6.7-6.4 3.7-16.8 3.7-23.2 0-3.2-1.9-4.9-4.3-4.9-6.8v-4.9c0 2.5 1.6 4.9 4.9 6.8 6.4 3.7 16.8 3.7 23.2 0 3.2-1.8 4.8-4.2 4.8-6.7z"
          className="color86aef6 svgShape"
        ></path>
        <path
          fill="#ffebeb"
          d="M371.9 300c6.4 3.7 6.5 9.7.1 13.5-6.4 3.7-16.8 3.7-23.2 0-6.4-3.7-6.5-9.7-.1-13.5 6.4-3.8 16.8-3.8 23.2 0z"
          className="colorebf3ff svgShape"
        ></path>
      </g>
      <g className="color000000 svgShape">
        <path
          fill="#f96232"
          d="M282.2 340.8L289 341.9 294.8 339.1 294.7 343.3 300.5 345.4 293.6 347 291.4 351.1 287.3 347.9 280.1 348.3 284.4 344.7z"
          className="colorf9dc32 svgShape"
        ></path>
      </g>
      <path
        fill="#ff5a26"
        d="M330.8 327.8c.3-2 2.5-6.4-1.8-7.8-2.5-.8-4.7-.4-6.2.4-.1.1-.2.2-.4.2-1.9 1.3-1.1 2.5-1 2.7-.4 0-2.9 0-4.7 1.2-.2.2-.5.4-.7.6-.8.9-.6 2 .9 3.1 3.3 2.2 10.5.2 13.9-.4z"
        className="colorff3926 svgShape"
      ></path>
      <path
        fill="#f6a686"
        d="M354.9 303.5L352.8 309 356.9 310.4 365.5 309.3 364.5 308.4 357.3 309 354.9 308.3 356.6 303.8z"
        className="color86aef6 svgShape"
      ></path>
      <g className="color000000 svgShape">
        <path
          fill="#9a9a9a"
          d="M359.8 300.9c1.5.9 1.5 2.3 0 3.2-1.5.9-4 .9-5.5 0s-1.5-2.3 0-3.2c1.5-.8 4-.8 5.5 0z"
          className="color5dd774 svgShape"
        ></path>
      </g>
      <g className="color000000 svgShape">
        <path
          fill="#9a9a9a"
          d="M369.7 305.9c1.5.9 1.5 2.3 0 3.2-1.5.9-4 .9-5.5 0s-1.5-2.3 0-3.2c1.5-.9 4-.9 5.5 0z"
          className="color5dd774 svgShape"
        ></path>
      </g>
      <g className="color000000 svgShape">
        <path
          fill="#9a9a9a"
          d="M357.6 307.9c1.5.9 1.5 2.3 0 3.2-1.5.9-4 .9-5.5 0s-1.5-2.3 0-3.2c1.5-.9 4-.9 5.5 0z"
          className="color5dd774 svgShape"
        ></path>
      </g>
      <g className="color000000 svgShape">
        <path
          fill="#ff693a"
          d="M74.9 291.5c.4-.2.8-.3 1.1-.1l1.3.7-.9 1.5-.9-.1v4.6l-4.6 2.7v-4.6l-.9 1.1-.9-.5 1.3-2.2c.3-.5.7-1 1.1-1.2l.6-.4c0 .1.1.1.1.1.2.2.5.2.9 0s.7-.6.9-1c0-.1.1-.2.1-.3l.8-.3z"
          className="colorffb63a svgShape"
        ></path>
      </g>
      <g className="color000000 svgShape">
        <path
          fill="#db5e37"
          d="M113.4 290.7s.9.2 1.6-.6c.7-.8 1.6-3.3 1.6-3.7 0-.4.1-.6-.2-.8-.2-.1-1 .9-1.4 1.4-.3.5-.9 1.7-1.6 2.4-.6.7 0 1.3 0 1.3z"
          className="colordb9837 svgShape"
        ></path>
        <path
          fill="#ff693a"
          d="M116.4 290.8l-.4-.2.1-2 .4-.8s.1 1.7-.1 3z"
          className="colorffb63a svgShape"
        ></path>
        <path
          fill="#ff693a"
          d="M116.5 285.6s1.2.6.3 2.8c0 0-.2 1.8-.2 2.3l-.2.1s.1-2.4 0-2.4c0 0-.6.9-.9 2.2 0 0-.1 1.6-3.1 1.3l.1-.1.9-1.2s.9.2 1.4-.3c.6-.6 1.7-3.8 1.7-3.8l.1-.6-.1-.3z"
          className="colorffb63a svgShape"
        ></path>
        <path
          fill="#ff693a"
          d="M113.5 289.4s-1.9 2.3-1.3 2.5c1.2.4 2.4-.6 3.1-1.6.7-1.2-3.1 2-1.8-.9z"
          className="colorffb63a svgShape"
        ></path>
        <path
          fill="#ff693a"
          d="M113.5 289.4s-1.7 2.1-1.1 2.3c1.1.3 2.7-.9 2.9-1.5.1-.3-3.4 2.2-1.8-.8z"
          className="colorffb63a svgShape"
        ></path>
      </g>
    </svg>
  );
}

const Intro = ({ scrollToRef }) => {
  return (
    <React.Fragment>
      <div className={styles.section} ref={scrollToRef}>
        <div >
          <div className={styles.gallery}>
            <ScrollParallax className={styles.preview} animateIn="fadeInUp">
              {Icon()}
            </ScrollParallax>
          </div>
          <div className={styles.wrap}>
            <h2 className={cn("h4", styles.title)}>
              How it works
            </h2>
            <div className={styles.text}>
              Whether you're buying groceries, fashion, electronics, or anything in between, you're already doing what you love. There's no need to change your shopping habits; just continue as usual.
            </div>
            <div className={styles.text}>
              With the Cashback Advantage, you get a percentage of your spending back as cashback rewards. This means that for every dollar you spend, you're putting money back into your pocket. It's like getting paid to shop!
            </div>
            <ul className={styles.list}>
              {items.map((x, index) => (
                <li className={styles.item} key={index}>
                  {x}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Intro;
