import React from "react";
import cn from "classnames";
import styles from "./Story.module.sass";
import pstyles from "../../../components/Page/Page.module.sass";

const Story = () => {
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>         
          <div className={styles.col}>
            <h2 className={cn("h2", styles.title)}><span className={pstyles.txttick11}>Who we are</span></h2>
            <div className={styles.info}>
              BillDrive provide an online digital solution for prepaid mobile recharge and bill payment related queries. Whether it is your prepaid recharge, postpaid bill payment, DTH, electricity, landline, gas, water or broadband, we have a platform which covers everything.
              BillDrive is an online digital platform that ensures a one-stop solution for all your utility needs. We have more happy customers to whom we have provided our services.
              Just select the service you are looking for, fill the form with your details, select the best available plan and start enjoying your plan after a successful payment. If you face any issues, we are there to help you.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
