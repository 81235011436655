import React from "react";
import cn from "classnames";
import styles from "./Faq.module.sass";
import Item from "./Item";
import data from "../../../mocks/data";

const Faq = (props) => {
  var title = "Frequently Asked Questions";
  var d = data.all_faq.find((x) => x.url === props.category);
  if(d !== undefined){
    title = d.title + " FAQ"
  }
  
  return (
    <div className={cn(styles.faqtop)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.list}>
          { 
            d !== undefined ?
              data.all_faq
                .find((x) => x.url === props.category)
                .faq.map((x, index) => (
                  <Item item={x} key={index} />
                )) 
            : ""
          }
        </div>
      </div>
    </div>
  );
};

export default Faq;
