import React from "react";
import Faq from "./Faq";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams } from "react-router-dom";

const Faqs = () => {
  let { category } = useParams();
  return (
    <HelmetProvider>
      <Helmet>
        <title>BillDrive FAQ's</title>
        <meta property="title" content="BillDrive FAQ's" /> 
        <meta name="description" content="Everything you need to know about BillDrive" />
        <meta property="og:title" content="BillDrive FAQ's" />
        <meta name="og:description" content="Everything you need to know about BillDrive" />
        <meta property="og:url" content="https://billdrive.in/faq" />
        <meta property="twitter:title" content="BillDrive FAQ's" />
        <meta name="twitter:description" content="Everything you need to know about BillDrive" />
      </Helmet>
      <Faq category={category} />
    </HelmetProvider>
  );
};

export default Faqs;
