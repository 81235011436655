import React from "react";
import cn from "classnames";
import styles from "./Desc.module.sass";

const Team = () => {

  return (
    <div className={cn("sectionprivacy", styles.sectionprivacy)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("containerhead", styles.containerhead)}>
          <div className={cn("stage-small", styles.stage)}>
            Getting started with
          </div>
          <h2 className={cn("h2", styles.titlehead)}>Ship and Delivery Policy</h2>
        </div>
        <div className={styles.top}>
          <div className={styles.info}>
          Welcome to BillDrive, your trusted platform for seamless BBPS bill payments and recharge services. We strive to provide you with a hassle-free experience, and our Ship and Delivery Policy is designed to keep you informed about the delivery of our services.
          </div>
          <h4 className={cn("h4", styles.title)}>Digital Delivery</h4>
          <div className={styles.info}>
            At BillDrive, we specialize in digital services, primarily focusing on BBPS bill payments and recharges. As such, our services are delivered electronically, and there is no physical product or item to be shipped.
          </div>

          <h4 className={cn("h4", styles.title)}>Instant Service Activation</h4>
          <div className={styles.info}>
          Enjoy the convenience of instant service activation upon successful completion of your bill payment or recharge. Once your payment is confirmed, your service will be activated promptly, ensuring you can enjoy uninterrupted connectivity and utility services.
          </div>
        
          <h4 className={cn("h4", styles.title)}>Service Access</h4>
          <div className={styles.info}>
          For bill payments, you can access your payment receipts and transaction history directly from your BillDrive account. For recharge services, your account balance or plan details will be updated instantly, and you can check them through your account dashboard.
          </div>

          <h4 className={cn("h4", styles.title)}>Customer Support</h4>
          <div className={styles.info}>
          If you encounter any issues with your bill payment or recharge, our customer support team is here to assist you. Reach out to us via support@billdrive.in, and we'll promptly address your concerns to ensure a smooth experience.
          </div>
         
          <h4 className={cn("h4", styles.title)}>Policy Updates</h4>
          <div className={styles.info}>
          Please note that this Ship and Delivery Policy may be subject to updates. It is advisable to check this page periodically for any changes. By continuing to use our services, you agree to the latest version of our policies.
          </div>
          <div className={styles.info}>
          Thank you for choosing BillDrive for your BBPS bill payment and recharge needs. We appreciate your trust and look forward to serving you efficiently.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
